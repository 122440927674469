import React from 'react';
import { Box, Card, CardContent, Avatar, Typography, Button } from '@mui/material';
import { Edit2 } from 'lucide-react';
import profileImage from '../../../Assets/Images/profileImage.png'

const profileData = {
    profile: {
        avatarPath: profileImage,
        name: "Shekar Anugula",
        email: "shekar.a@abilioit.com",
        mobileNumber: "8142447135",
        personalInfo: {
            firstName: "Shekar",
            lastName: "Anugula",
            emailID: "shekar.a@abilioit.com",
            mobileNumber: "8142447135",
            address: "Kukatpally, Hyderabad"
        }
    }
};

const ProfileDetails = () => {
    const { profile } = profileData;

    return (
        <div>
            <Typography variant="h5" sx={{ marginBottom: '15px' }}>My Profile</Typography>
            <Box>
                <Card sx={{ display: 'flex', alignItems: 'center', padding: '20px', marginBottom: '20px', border: '1px solid #E4E4E4', boxShadow: 'none' }}>
                    <Avatar
                        alt={profile.name}
                        src={profile.avatarPath}
                        sx={{ width: 100, height: 100, marginRight: '20px' }}
                    />
                    <CardContent>
                        <Typography variant="h5">{profile.name}</Typography>
                        <Typography variant="body1">{profile.email}</Typography>
                        {/* <Typography variant="body1">Mobile: {profile.mobileNumber}</Typography> */}
                    </CardContent>

                </Card>

                <Card sx={{ padding: '20px', border: '1px solid #E4E4E4', boxShadow: 'none' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">Personal Info</Typography>
                        <Button variant="outlined" sx={{ border: 'none', display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <Edit2 style={{ height: '15px' }} />  Edit
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection:'column', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Box sx={{ display: 'flex', marginTop:'15px' }}>
                            <Box sx={{width:'350px'}}>
                                <Typography variant="body2">First Name</Typography>
                                <Typography variant="h6">{profile.personalInfo.firstName}</Typography>
                            </Box>
                            <Box sx={{width:'350px'}}>
                                <Typography variant="body2">Last Name</Typography>
                                <Typography variant="h6">{profile.personalInfo.lastName}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: '20px' }}>
                            <Box sx={{width:'350px'}}>
                                <Typography variant="body2">Email ID</Typography>
                                <Typography variant="h6">{profile.personalInfo.emailID}</Typography>
                            </Box>
                            <Box sx={{width:'350px'}}>
                                <Typography variant="body2">Mobile Number</Typography>
                                <Typography variant="h6">{profile.personalInfo.mobileNumber}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{marginTop:'20px'}}>
                            <Typography variant="body2">Address</Typography>
                            <Typography variant="h6">{profile.personalInfo.address}</Typography>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </div>
    );
}

export default ProfileDetails;
