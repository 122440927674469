import React from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, Chip, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import useResponsive from '../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
const Membership = () => {

    const { isSmallScreen, isMediumScreen } = useResponsive();

    const navigate = useNavigate()

    const hadleNavigateContact = () => {
        navigate("/contactus")
    }

    return (
        <Container>
            <Box sx={{ textAlign: 'center', margin: '5rem 0 ', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '20px' }}>
                <Typography variant="h2" fontWeight={700} lineHeight={1} >
                    Find the <span style={{ color: '#7E60BF' }}>Right Plan.</span>
                </Typography>
                <Typography variant="body1" maxWidth={750} color='#BCBCBC'>
                    It is a long established fact that a reader will be distracted by the readable content of
                    a page when looking at its layout. The point of using Lorem Ipsum is that it has
                    a more-or-less normal distribution of letters, as opposed to using Content here,
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" flexWrap="wrap" margin="2rem 0">

                {/* Standard Plan */}
                <Card
                    sx={{
                        bgcolor: '#FFF',
                        color: 'text.primary',
                        margin: '10px',
                        marginTop: '20px',
                        width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '31%',
                        padding: '20px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        boxShadow: 'none',
                        border: '1px solid #E4E4E4',
                        borderRadius: '15px',
                    }}
                >
                    <CardContent>
                        <Typography variant="h4" sx={{ margin: '0 0 25px 0' }}>
                            Standard
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '10px', margin: '20px 0 10px 0' }}>
                            <Typography variant="h3" fontWeight={700} sx={{ color: '#7E60BF' }}>
                                ₹299
                            </Typography>
                            <Typography>3 months</Typography>
                        </Box>
                        <Typography variant="body2" color='black'>
                            Overview of the design and layout of workspaces, emphasising a modern and comfortable environment.
                        </Typography>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'15px'}}>
                            <CircleIcon sx={{fontSize:'7px', color:'#E4E4E4'}}/>
                            <Divider sx={{borderWidth: '0.5px', borderColor: '#E4E4E4', width:'97%' }} />
                            <CircleIcon sx={{fontSize:'7px', color:'#E4E4E4'}}/>
                        </Box> 
                        <Typography variant="h5" mt={2} fontWeight={600}>
                            Overview
                        </Typography>
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Basic House Cleaning
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Basic Kitchen Cleaning
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Bathroom Maintenance
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Window Cleaning
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Laundry Support
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Trash Disposal
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Customer Support
                                </Typography>
                            </li>
                        </ul>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{
                            boxShadow: 'none', border: '1.5px solid grey', width: '180px', borderRadius: '20px', backgroundColor: 'white', color: 'black', fontWeight: '800',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }}>
                            Get Started
                        </Button>
                    </CardActions>
                </Card>

                {/* Professional Plan */}
                <Card
                    sx={{
                        bgcolor: '#7E60BF',
                        color: 'white',
                        margin: '10px',
                        marginTop: '20px',
                        width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '31%',
                        padding: '20px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: '15px',
                        position: 'relative',
                        boxShadow: 'none',
                        border: '1px solid #E4E4E4',
                        overflow: 'visible',
                    }}
                >
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '0px', position: 'relative' }}>
                            <Chip
                                label="Most Popular"
                                color="primary"
                                sx={{
                                    bgcolor: '#FFB700',
                                    color: 'black',
                                    fontWeight: 'bold',
                                    position: 'absolute',
                                    width: '140px',
                                    height: '40px',
                                    top: '-55px', // Move Chip upwards, halfway outside the card
                                    left: '50%',
                                    transform: 'translateX(-50%)', // Center the Chip horizontally
                                    borderRadius: '20px',
                                    border: '2px solid white',
                                    zIndex: 1,
                                }}
                            />
                        </Box>
                        <Typography variant="h4" sx={{ margin: '0 0 25px 0' }}>
                            Professional
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '10px', margin: '20px 0 10px 0' }}>
                            <Typography variant="h3" fontWeight={700} >
                                ₹499
                            </Typography>
                            <Typography sx={{ color: 'white' }}>6 months</Typography>
                        </Box>
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            Overview of the design and layout of workspaces, emphasising a modern and comfortable environment.
                        </Typography>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'15px'}}>
                            <CircleIcon sx={{fontSize:'7px', color:'#E4E4E4'}}/>
                            <Divider sx={{borderWidth: '0.5px', borderColor: '#E4E4E4', width:'97%' }} />
                            <CircleIcon sx={{fontSize:'7px', color:'#E4E4E4'}}/>
                        </Box>                        <Typography variant="h5" mt={2} fontWeight={600}>
                            Overview
                        </Typography>
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    All Features Of The 3Month Plan
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    Deep Cleaning Monthly
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    Window Cleaning
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    PetCare Assistance
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    Grocery Assistance
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    Flexible Scheduling
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0', color: 'white' }}>
                                    Priority Support
                                </Typography>
                            </li>
                        </ul>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{
                            boxShadow: 'none', width: '180px', borderRadius: '20px', backgroundColor: 'white', color: '#7E60BF', fontWeight: '800',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }}>
                            Get Started
                        </Button>
                    </CardActions>
                </Card>


                {/* Ultimate Plan */}
                <Card
                    sx={{
                        bgcolor: '#FFF',
                        color: 'text.primary',
                        margin: '10px',
                        marginTop: '20px',
                        width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '31%',
                        padding: '20px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        boxShadow: 'none',
                        border: '1px solid #E4E4E4',
                        borderRadius: '15px',
                    }}
                >
                    <CardContent>
                        <Typography variant="h4" sx={{ margin: '0 0 25px 0' }}>
                            Ultimate
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '10px', margin: '20px 0 10px 0' }}>
                            <Typography variant="h3" fontWeight={700} sx={{ color: '#7E60BF' }}>
                                ₹799
                            </Typography>
                            <Typography>12 months</Typography>
                        </Box>
                        <Typography variant="body2" color='black'>
                            Overview of the design and layout of workspaces, emphasising a modern and comfortable environment.
                        </Typography>
                        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'15px'}}>
                            <CircleIcon sx={{fontSize:'7px', color:'#E4E4E4'}}/>
                            <Divider sx={{borderWidth: '0.5px', borderColor: '#E4E4E4', width:'97%' }} />
                            <CircleIcon sx={{fontSize:'7px', color:'#E4E4E4'}}/>
                        </Box>                         <Typography variant="h5" mt={2} fontWeight={600}>
                            Overview
                        </Typography>
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    All Features Of The 6Month Plan
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Comprehensive Cleaning
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Garden And Outdoor Maintenance
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Childcare Assistance
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    Special Requests
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" sx={{ fontWeight: '500', margin: '12px 0' }}>
                                    24/7 Customer Support
                                </Typography>
                            </li>
                        </ul>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" sx={{
                            boxShadow: 'none', border: '1.5px solid grey', width: '180px', borderRadius: '20px', backgroundColor: 'white', color: 'black', fontWeight: '800',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }}>
                            Get Started
                        </Button>
                    </CardActions>
                </Card>

            </Box>
            <Box
                sx={{
                    border: '1px solid #7E60BF',
                    borderRadius: '8px',
                    padding: isSmallScreen ? '30px' : '30px 50px',
                    backgroundColor: '#f0ebfb',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // maxWidth: '1200px',
                    margin: isSmallScreen ? '10px' : '50px',
                    flexDirection: isSmallScreen ? 'column' : ''
                }}
            >
                <Box>
                    <Typography
                        variant="h5"
                        sx={{ color: '#7E60BF', fontWeight: 'bold' }}
                    >
                        For payment queries?
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{ color: '#7E60BF', fontWeight: 'bold' }}
                    >
                        Contact us.
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '10px', maxWidth: '500px' }}
                    >
                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                    </Typography>
                </Box>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#7E60BF',
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '20px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        margin: '30px 0'
                    }}
                    onClick={hadleNavigateContact}
                >
                    Contact Now
                </Button>
            </Box>
        </Container>
    );
}

export default Membership;
