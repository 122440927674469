import React, { useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, Chip, Divider, Tooltip, IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import useResponsive from '../../../hooks/useResponsive';
import { useNavigate, useParams } from 'react-router-dom';

import maidImg1 from '../../../Assets/Images/maidImage1.png'
import moneyBadge from '../../../Assets/Images/moneyBadge.png'

import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimerIcon from '@mui/icons-material/Timer';
import { ArrowLeftIcon, BadgeIndianRupee, Info } from 'lucide-react';
import BookingCancellation from '../../../popup/BookingPopUp/BookingCancellation';


const bookingsData = [
    {
        id: 1,
        title: 'Breakfast',
        date: 'Tue, July 21st, 2020',
        time: '12:00 PM',
        status: 'Accepted',
        image: 'DosaImage',
        maidDetails: [
            {
                id: 1,
                name: 'Rohit Reddy',
                phone_number: '1234567890',
                status: 'accepted',
                gender: 'male',
                languages: 'Telugu, Hindi, English',
                image: maidImg1,
                maidLocation: 'madhapur',
                amount: 300,
                address: '123 Elm Street, Springfield, IL',
                slot: 'Daily-sat, Aug31, 9:00AM'
            }
        ]
    },
    {
        id: 2,
        title: 'Combo\'s',
        date: 'Fri, July 17th, 2020',
        time: '12:00 PM',
        status: 'Scheduled',
        image: 'MealsImage',

        maidDetails: [
            {
                id: 2,
                name: 'Anushka',
                gender: 'female',
                languages: 'Telugu, Hindi, English',
                image: 'https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png',
                phone_number: '0987654321',
                maidLocation: 'madhapur',
                amount: 200,
                status: 'scheduled',
                address: '456 Maple Avenue, Springfield, IL',
                slot: 'Daily-sat, Sep7, 12:00PM'
            }
        ]
    },
    {
        id: 3,
        title: 'Lunch + Dinner',
        date: 'Sat, June 6th, 2020',
        time: '1:00 PM',
        status: 'Cancelled',
        image: 'RiceImage',
        maidDetails: [
            {
                id: 3,
                name: 'Sampoornesh Babu',
                gender: 'male',
                languages: 'Telugu, Hindi, English, Urdu, Kannada',
                phone_number: '1234567890',
                status: 'cancelled',
                image: 'https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png',
                maidLocation: 'madhapur',
                amount: 300,
                address: '123 Elm Street, Springfield, IL',
                slot: 'Daily-sat, Aug31, 9:00AM'
            }
        ]
    },
    {
        id: 4,
        title: 'Dinner',
        date: 'Tue, July 24st, 2020',
        time: '4:00 PM',
        status: 'InProgress',
        image: "DosaImage",
        maidDetails: [
            {
                id: 4,
                name: "Bhanu",
                gender: 'male',
                languages: 'Telugu, Hindi, English, Urdu, Kannada',
                phone_number: "1234567890",
                status: "inprogress",
                image: "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
                maidLocation: "kukatpally",
                amount: 450,
                address: "123 Elm Street, Springfield, IL",
                slot: "Daily-sat, Aug31, 9:00AM"
            }
        ]
    },
];

const BookingDetails = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();
    const [isCancellationOpen, setCancellationOpen] = useState(false);
    const [showBreakup, setShowBreakup] = useState(false);
    const { id } = useParams();
    const bookingId = parseInt(id, 10);
    const navigate = useNavigate();

    const booking = bookingsData.find(booking => booking.id === bookingId);

    if (!booking) {
        return <Box>Booking not found</Box>;
    }

    const toggleBreakup = () => {
        setShowBreakup(prev => !prev);
    };
    const handleHelpClick = () => {
        navigate('/faq');
    };

    const handleCancelBookingClick = () => {
        setCancellationOpen(true);
    };

    const handleCloseCancellation = () => {
        setCancellationOpen(false);
    };

    return (
        <Container>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0 20px 0', alignItems: 'center', width: '100%' }}>
                <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={() => navigate(-1)} > <ArrowLeftIcon style={{ width: '15px' }} /> Back</Typography>
                <Typography variant="h5" color="initial" sx={{ fontWeight: '700' }}>Booking Details</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                <Box key={booking.id} width={isSmallScreen ? '100%' : isMediumScreen ? '100%' : '100%'}>
                    <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #E4E4E4', backgroundColor: '#F7F7F7', borderRadius: '5px' }}>
                        <Box>
                            <Typography variant="h5" fontWeight={600} fontSize={22}>{booking.title}</Typography>
                            <Typography color="textSecondary" sx={{ color: '#BCBCBC', fontSize: '14px' }}>{booking.date} | {booking.time}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                            <Button variant='outlined' sx={{ border: '1px solid black', borderRadius: '20px', fontWeight: '700', fontSize: '12px !important', color: 'black', width: '100px' }} onClick={handleHelpClick}>Help</Button>
                            <Button variant='outlined' sx={{ borderRadius: '20px', fontWeight: '700', fontSize: '12px !important', backgroundColor: '#FF0000', color: 'white', width: '100px' }}>SOS Call</Button>
                        </Box>
                    </CardContent>
                    {booking.maidDetails.map(maid => (
                        <Box key={maid.id} mt={2} sx={{ display: 'flex', flexDirection: isMediumScreen ? 'column' : isSmallScreen ? 'column' : '', justifyContent: 'space-between', margin: '25px 0' }}>
                            <Box>
                                <Box sx={{ width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Box sx={{ border: '1px solid #E4E4E4', borderRadius: '5px' }}>
                                        <Box sx={{ padding: '18px' }}>
                                            <Typography variant="h6" color="initial" pb={2} sx={{ fontWeight: '800', fontSize: '18px' }}>Maid Details</Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '3rem' }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                                                    <img src={maid.image} height={70} width={70} style={{ borderRadius: '10px' }} />
                                                    <Box >
                                                        <Typography variant="h5" sx={{ fontWeight: '800', fontSize: '18px' }}>{maid.name}</Typography>
                                                        <Typography variant="body2" sx={{ fontWeight: '600', lineHeight: '20px' }}>Gender : {maid.gender}</Typography>
                                                        <Typography variant="body2" sx={{ fontWeight: '600', lineHeight: '20px' }}>Language : {maid.languages}</Typography>
                                                    </Box>
                                                </Box>
                                                <Button color="" sx={{ backgroundColor: '#01D74C', width: '30px', minWidth: '0px !important', height: '30px', color: 'white', borderRadius: '5px' }}> <CallIcon /> </Button>
                                            </Box>
                                            <Divider sx={{ padding: '10px 0' }} />
                                            <Box>
                                                <Typography variant="body2" color="initial" pb={2} sx={{ color: 'red', textAlign: 'center', paddingTop: '20px', paddingBottom: '0' }}>Free cancellation/reschedule before 1 hour.</Typography>
                                            </Box>
                                        </Box>

                                    </Box>

                                    <Box >
                                        <Typography variant="h6" color="initial" sx={{ fontWeight: '800', fontSize: '18px', padding: '30px 0 5px 0 ' }}>Cancellation Policy</Typography>
                                        <Typography variant="body2" color="initial">Free cancellations if done more than 3 hrs before the service or if a professional isn’t assigned. A fee will be charged otherwise.</Typography>
                                        <a href='' style={{ color: '#7E60BF', fontWeight: '700' }} >Read More</a>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '49%',
                                    border: '1px solid #E4E4E4',
                                    borderRadius: '5px',
                                    maxHeight: 'calc(90vh - 120px)', // Adjust this value based on header/footer height
                                    overflowY: 'auto', // Enable vertical scrolling
                                    '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar
                                }}
                            >
                                <Box sx={{ padding: '18px' }}>
                                    <Typography
                                        variant="h6"
                                        color="initial"
                                        pb={1}
                                        sx={{ fontWeight: '800', fontSize: '18px' }}
                                    >
                                        Booking Details
                                    </Typography>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '8px 0',
                                            }}
                                        >
                                            <PersonIcon sx={{ fontSize: '20px' }} /> {maid.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '8px 0',
                                            }}
                                        >
                                            <CallIcon sx={{ fontSize: '20px' }} /> {maid.phone_number}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '8px 0',
                                                paddingBottom: '1rem',
                                            }}
                                        >
                                            <EmailIcon sx={{ fontSize: '20px' }} /> {maid.name}
                                        </Typography>
                                        <Divider />
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '20px 0',
                                            }}
                                        >
                                            <LocationOnIcon sx={{ fontSize: '20px' }} /> {maid.address}
                                        </Typography>
                                        <Divider />
                                        <Typography
                                            variant="body2"
                                            color="initial"
                                            sx={{
                                                fontWeight: '600',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '15px',
                                                padding: '20px 0',
                                            }}
                                        >
                                            <TimerIcon sx={{ fontSize: '20px' }} /> {maid.slot}
                                        </Typography>
                                        <Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '20px', gap:'3.5rem' }}>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        border: '1px solid black',
                                                        color: 'black',
                                                        borderRadius: '20px',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important',
                                                        marginLeft: '34px',
                                                    }}
                                                    disabled={maid.status === 'inprogress' || maid.status === 'cancelled'}
                                                >
                                                    Reschedule
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        border: '1px solid #7E60BF',
                                                        borderRadius: '20px',
                                                        fontWeight: '700',
                                                        fontSize: '12px !important',
                                                    }}
                                                    disabled={maid.status === 'inprogress' || maid.status === 'cancelled'}
                                                    onClick={handleCancelBookingClick}
                                                >
                                                    Cancel Booking
                                                </Button>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '15px',
                                                            padding: '20px 0 5px 0',
                                                        }}
                                                    >
                                                        <img src={moneyBadge} height={20} width={20} />
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{ fontWeight: '600', display: 'flex', alignItems: 'center', gap: '15px' }}
                                                        >
                                                            ₹{maid.amount}
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px'
                                                        // padding: '20px 0 5px 0',
                                                    }}>
                                                        <Typography variant="" color="initial" sx={{ color: '#0DC07B', fontWeight: '900', paddingLeft: '2.3rem' }}>✓</Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{ color: '#0DC07B', fontWeight: '700' }}
                                                        >
                                                            Paid Online
                                                        </Typography>
                                                    </Box>
                                                </Box>


                                                <a
                                                    style={{ color: '#7E60BF', fontWeight: '700', textDecoration: 'none', cursor: "pointer" }}
                                                    onClick={toggleBreakup}
                                                >
                                            {showBreakup ? "Hide " : "View Breakup"}
                                                </a>
                                            </Box>

                                            {showBreakup && (
                                                <Box
                                                    sx={{
                                                        border: '1px solid #E4E4E4',
                                                        p: 2,
                                                        borderRadius: '10px',
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                                        Payment Breakdown
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{fontSize:'13px'}}>Item's Total</Typography>
                                                        <Typography variant="body2">₹259</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{fontSize:'13px'}}>Delivery Tip</Typography>
                                                        <Typography variant="body2">₹20</Typography>
                                                    </Box>
                                                    <hr />
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{fontSize:'13px'}}>
                                                            Platform fee
                                                            <Tooltip
                                                                title={
                                                                    <>
                                                                        <Typography variant="subtitle2">Platform fee</Typography>
                                                                        <Typography variant="body2" sx={{ color: '#ccc' }}>
                                                                            This fee helps us operate and improve our platform, delivering a seamless app experience.
                                                                        </Typography>
                                                                    </>
                                                                }
                                                                placement="top"
                                                            >
                                                                <IconButton size="small" style={{ marginLeft: 4 }}>
                                                                    <Info style={{ height: '13px', width: '15px' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                        <Box display="flex" alignItems="center">
                                                            <Typography
                                                                variant="body1"
                                                                color="text.secondary"
                                                                sx={{ textDecoration: 'line-through', marginRight: '8px', fontSize:'12px' }}
                                                            >
                                                                ₹7.00
                                                            </Typography>
                                                            <Typography variant="body2" color="text.primary">
                                                                ₹3.00
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{fontSize:'13px'}}>Extra Discount</Typography>
                                                        <Typography variant="body2">₹20</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography variant="body2" sx={{fontSize:'13px'}}>GST & Service Charges</Typography>
                                                        <Typography variant="body2">₹13.43</Typography>
                                                    </Box>
                                                </Box>
                                            )}

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    ))}
                </Box>
            </Box>

            {isCancellationOpen && <BookingCancellation onClose={handleCloseCancellation} />}
        </Container>
    );
}

export default BookingDetails;
