import React, { useContext, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { X } from "lucide-react";
import { Context } from "../context/Context";
import useResponsive from "../hooks/useResponsive";
import FilterPopUp from "./FilterPopUp";

const modalStyle = (isSmallScreen, isMediumScreen) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  outline: "none",
  borderRadius: "6px",
  maxHeight: "80vh",
  overflowY: "auto",
});

const CategoryPopUp = () => {
  const { isMediumScreen, isSmallScreen } = useResponsive();
  const { serviceFields, openCategoryPopUp, handleOpenFilterPopUp, openFilterPopUp, handleCloseCategoryPopUp } = useContext(Context);




  const handleStoreServiceFields = async (service) => {
    const filteredCategory = serviceFields.serviceCategory.filter(
      (val) => val.title === service.title
    );

    if (filteredCategory.length > 0) {
      localStorage.setItem("serviceFields", JSON.stringify(filteredCategory));
      console.log("Opening FilterPopUp");
      handleCloseCategoryPopUp()
      handleOpenFilterPopUp();
    } else {
      console.error("No matching service found");
    }
  };

  return (
    <Box>
      <Modal open={openCategoryPopUp} onClose={handleCloseCategoryPopUp}>
        <Box sx={modalStyle(isSmallScreen, isMediumScreen)}>
          <Box sx={{ borderRadius: "10px", padding: isSmallScreen ? "20px" : "40px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                What are you looking for?
              </Typography>
              <X cursor="pointer" onClick={handleCloseCategoryPopUp} />
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px", justifyContent: "center" }}>
              {serviceFields && serviceFields.serviceCategory && serviceFields.serviceCategory.length > 0 ? (
                serviceFields.serviceCategory.map((item) => (
                  <Box key={item.id} sx={{ display: "flex", flexDirection: "column", textAlign: "center", gap: "10px" }}>
                    <Box
                      sx={{
                        border: "1px solid #BCBCBC",
                        padding: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleStoreServiceFields(item)}
                    >
                      <img src={item.imgSrc} width="30px" height="30px" alt={item.name || "Category Icon"} />
                    </Box>
                    <Typography variant="h6" sx={{ color: "#000000" }}>
                      {item.title}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No categories available.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      {openFilterPopUp && <FilterPopUp />}
    </Box>
  );
};

export default CategoryPopUp;
