import React from 'react'
import { Box, Card, CardContent, Avatar, Typography, Button } from '@mui/material';


const Notifications = () => {
  return (
    <div>
        <Typography variant="h5" sx={{ marginBottom: '15px' }}>Notifications</Typography>
    </div>
  )
}

export default Notifications