import React, { useState } from 'react';
import { Box, Button, RadioGroup, Typography, IconButton } from '@mui/material';
import { CheckCircle, Height, RadioButtonUnchecked } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

function BookingCancellation({ onClose }) {
    const [selectedReason, setSelectedReason] = useState('Booking address is incorrect');

    const handleReasonChange = (value) => {
        setSelectedReason(value);
    };


    const CustomRadio = ({ value, selectedValue, label }) => (
        <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '8px', }} onClick={() => handleReasonChange(value)}>
            {selectedValue === value ? (
                <CheckCircle sx={{ color: '#7E60BF', fontSize: '20px', marginRight: '8px' }} />
            ) : (
                <RadioButtonUnchecked sx={{ color: 'black', fontSize: '20px', marginRight: '8px' }} />
            )}
            <Typography variant="body1">{label}</Typography>
        </Box>
    );

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1300,
            }}
        >
            <Box
                sx={{ width: '400px', display: 'flex', flexDirection: 'column', boxShadow: 24, borderRadius: '10px', }}>
                <Box sx={{ backgroundColor: '#f0ebfb', padding: '16px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', width: '92%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} >
                        <Typography variant="h5" sx={{ color: '#7E60BF', fontWeight: 'bold' }}>
                            Booking Cancellation
                        </Typography>
                        <IconButton >
                            <CloseIcon onClick={onClose} />
                        </IconButton>
                    </Box>

                    <Typography variant="body2" sx={{ color: 'gray', marginTop: '8px' }}>
                        We won’t be charging you a cancellation fee.
                    </Typography>
                </Box>

                <Box sx={{ padding: '24px', backgroundColor:'#fff', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                    <RadioGroup value={selectedReason} >
                        <CustomRadio
                            value="Booking address is incorrect"
                            selectedValue={selectedReason}
                            label="Booking address is incorrect."
                        />
                        <CustomRadio
                            value="Professional not assigned"
                            selectedValue={selectedReason}
                            label="Professional not assigned."
                        />
                        <CustomRadio
                            value="Placed the request by mistake"
                            selectedValue={selectedReason}
                            label="Placed the request by mistake."
                        />
                        <CustomRadio
                            value="Service no longer required"
                            selectedValue={selectedReason}
                            label="Service no longer required."
                        />
                        <CustomRadio
                            value="Hired someone else outside Sevaki"
                            selectedValue={selectedReason}
                            label="Hired someone else outside Sevaki." />
                    </RadioGroup>

                    <Box sx={{ textAlign: 'center', padding: '10px 0 10px 0' }}>
                        <Button variant="contained" sx={{ backgroundColor: 'primary.main', borderRadius: '20px' }} onClick={onClose}>
                            Cancel Booking
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default BookingCancellation;
