import React, { useContext } from 'react';
import { Box, Modal, Typography } from "@mui/material";
import { X } from "lucide-react";
import { Context } from '../../context/Context';
import useResponsive from '../../hooks/useResponsive';
import CategoryPopUp from '../../popup/CategoryPopUp';

const ServicePopup = () => {
    const {
        handleCloseServicePopup,
        openServicePopUp,
        services,
        setSelectService,
        openCategoryPopUp,
        handleOpenCategoryPopUp,getServicesFields
    } = useContext(Context);
    const { isSmallScreen, isMediumScreen } = useResponsive();

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "500px",
        height: "auto",
        bgcolor: "background.paper",
        boxShadow: 24,
        border: "none",
        outline: "none",
        borderRadius: "6px",
    };

    const handleServiceClick = async (service) => {
        console.log("Service clicked:", service.name);
        getServicesFields(service.id)
        handleCloseServicePopup(); // Close ServicePopUp
        handleOpenCategoryPopUp(); // Open CategoryPopUp
    };


    return (
        <Box>
            <Modal open={openServicePopUp} onClose={handleCloseServicePopup}>
                <Box sx={modalStyle}>
                    <Box sx={{ padding: "40px" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
                            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                What are you looking for?
                            </Typography>
                            <X cursor="pointer" onClick={handleCloseServicePopup} />
                        </Box>

                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
                            {services.map((item) => (
                                <Box key={item.id} sx={{ display: "flex", flexDirection: "column", textAlign: "center", gap: "10px" }}>
                                    <Box
                                        sx={{
                                            border: "1px solid #BCBCBC",
                                            padding: "7px 40px",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => handleServiceClick(item)} // Change function name here
                                    >
                                        <img src={item.imagePath} width="30px" height="30px" alt="not found" />
                                    </Box>
                                    <Typography variant="h6" sx={{ color: "#000000" }}>{item.name}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {openCategoryPopUp && <CategoryPopUp />} {/* Ensure this is correctly placed */}
        </Box>
    );
};

export default ServicePopup;
