import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Container,
  Chip,
  Divider,
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";

import DosaImage from "../../../Assets/Images/DosaImage.png";
import MealsImage from "../../../Assets/Images/MealsImage.png";
import RiceImage from "../../../Assets/Images/RiceImage.png";

import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Padding } from "@mui/icons-material";

const bookingsData = [
  {
    id: 1,
    title: "Breakfast",
    date: "Tue, July 21st, 2020",
    time: "12:00 PM",
    status: "Accepted",
    image: DosaImage,
    maidDetails: [
      {
        id: 1,
        name: "Service 1",
        phone_number: "1234567890",
        status: "accepted",
        image:
          "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
        maidLocation: "madhapur",
        amount: 300,
        address: "123 Elm Street, Springfield, IL",
        slot: "Daily-sat, Aug31, 9:00AM",
      },
    ],
  },
  {
    id: 2,
    title: "Combo's",
    date: "Fri, July 17th, 2020",
    time: "12:00 PM",
    status: "Scheduled",
    image: MealsImage,
    maidDetails: [
      {
        id: 2,
        name: "Service 2",
        image:
          "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
        phone_number: "0987654321",
        maidLocation: "madhapur",
        amount: 200,
        status: " not accepted",
        address: "456 Maple Avenue, Springfield, IL",
        slot: "Daily-sat, Sep7, 12:00PM",
      },
    ],
  },
  {
    id: 3,
    title: "Lunch + Dinner",
    date: "Sat, June 6th, 2020",
    time: "1:00 PM",
    status: "Cancelled",
    image: RiceImage,
    maidDetails: [
      {
        id: 1,
        name: "Service 1",
        phone_number: "1234567890",
        status: "accepted",
        image:
          "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
        maidLocation: "madhapur",
        amount: 300,
        address: "123 Elm Street, Springfield, IL",
        slot: "Daily-sat, Aug31, 9:00AM",
      },
    ],
  },
  {
    id: 4,
    title: "Dinner",
    date: "Tue, July 24st, 2020",
    time: "4:00 PM",
    status: "InProgress",
    image: DosaImage,
    maidDetails: [
      {
        id: 4,
        name: "Bhanu",
        phone_number: "1234567890",
        status: "inprogress",
        image:
          "https://res.cloudinary.com/dgzbpko6w/image/upload/v1724132314/file_mnjkje.png",
        maidLocation: "kukatpally",
        amount: 450,
        address: "123 Elm Street, Springfield, IL",
        slot: "Daily-sat, Aug31, 9:00AM",
      },
    ],
  },
];

const statusColors = {
  Accepted: "#0DC07B",
  Scheduled: "#FF8000",
  Cancelled: "#FF0000",
  InProgress: "yellow",
};

const MyBookings = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();

  const navigate = useNavigate();

  const handlebooking = (id) => {
    navigate(`/bookingdetails/${id}`);
  };

  return (
    <Container>
      <>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                maxWidth: "620px",
                justifyContent: "space-between",
                margin: "20px 0 10px 0",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                color="initial"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              >
                {" "}
                <ArrowLeftIcon style={{width:'15px'}} /> Back
              </Typography>
              <Typography
                variant="h5"
                color="initial"
                sx={{ fontWeight: "700" }}
              >
                My Bookings
              </Typography>
            </Box>
            <Box
              display="flex"
              // flexDirection="column"
              flexWrap='wrap'
              alignItems="center"
              width="100%"
              justifyContent='space-between'
            >
              {bookingsData.map((booking) => (
                <Box
                  key={booking.id}
                  width={
                    isSmallScreen ? "100%" : isMediumScreen ? "100%" : "49%"
                  }
                  
                >
                  <Card
                    style={{
                      margin: "10px 0",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "none",
                      border: "1px solid #E4E4E4",
                      cursor: "pointer",
                    }}
                    onClick={() => handlebooking(booking.id)}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "column" : "",
                        alignItems: isSmallScreen ? "" : "center",
                        justifyContent: "space-between",
                        width: "100%",
                        padding:'10px'
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={booking.image}
                          style={{
                            width: "80px",
                            height: "80px",
                            backgroundColor: "#f0f0f0",
                            margin: "10px",
                          }}
                        />
                        <Box sx={{ marginLeft: "15px" }}>
                          <Typography
                            variant="h5"
                            fontWeight={600}
                            fontSize={22}
                          >
                            {booking.title}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            sx={{ color: "#BCBCBC", fontSize: "14px" }}
                          >
                            {booking.date} | {booking.time}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Chip
                          label={booking.status}
                          style={{
                            backgroundColor: statusColors[booking.status],
                            color: "white",
                            width: "110px",
                          }}
                        />
                        <ArrowRightIcon style={{width:'20px'}} />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </>
    </Container>
  );
};

export default MyBookings;
