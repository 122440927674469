import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, TextField, InputAdornment, FormControlLabel, Checkbox, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Verification from './Verification';

const Loginsignup = ({ open, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openLogin, setOpenLogin] = useState(false);
  const [openVerification, setOpenVerification] = useState(false);


  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length <= 10) {
      setPhoneNumber(numericValue);
    }
    setError(false);
    setErrorMessage('');
  };

  const handleClose = () => setOpenLogin(false);

  const handleProceed = () => {
    setOpenLogin(false); // Close the login/signup modal
    setOpenVerification(true); // Open the verification modal
  };

  const modalStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '200px',
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: '6px',
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyle}>
          <IconButton
            sx={{ position: 'absolute', top: '-30px', right: '0px', padding: '2px' }}
            onClick={onClose}
          >
            <ClearIcon sx={{ backgroundColor: 'primary.main', color: '#fff', borderRadius: '50%', fontSize: 'medium' }} />
          </IconButton>

          <Typography variant="h5" gutterBottom>
            Login/ Signup
          </Typography>

          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', bgcolor: 'white', borderRadius: '2' }}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              sx={{ border: '1px solid #29292933' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    +91 &nbsp; <span style={{ height: '25px' }}>|</span>
                  </InputAdornment>
                ),
              }}
            />
            {errorMessage && (
              <Typography color="error" sx={{ mt: 1, textAlign: 'center' }}>
                {errorMessage}
              </Typography>
            )}
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Get order updates on WhatsApp"
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="button"
                onClick={handleProceed}  // Switch to Verification on click
                sx={{ width: '90px', color: '#fff', backgroundColor: 'primary.main' }}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Show the Verification modal when openVerification is true */}
      <Verification open={openVerification} setOpen={setOpenVerification} />
    </>
  );
};

export default Loginsignup;
