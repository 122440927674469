import React, { useState } from 'react';
import { Container, Box, Typography, IconButton, Collapse, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ArrowLeftIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../hooks/useResponsive'

const Faq = () => {
    const [openId, setOpenId] = useState(null);
    const navigate = useNavigate();
    const { isSmallScreen, isMediumScreen } = useResponsive()

    const faqs = [
        { id: 1, question: 'How do I pay for the Essentials or Premium plan?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 2, question: 'We need to add new users to our team. How will that be billed?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 3, question: 'My team wants to cancel its subscription. How do we do that? Can we get a refund?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 4, question: 'Do you offer discounts for non-profit organizations or educational institutions?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 5, question: 'Can I cancel my Essentials or Premium plan subscription at any time?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 6, question: 'We need to add new users to our team. How will that be billed?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 7, question: 'My team wants to cancel its subscription. How do we do that? Can we get a refund?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 8, question: 'We need to add new users to our team. How will that be billed?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },
        { id: 9, question: 'My team wants to cancel its subscription. How do we do that? Can we get a refund?', answer: 'You can pay with a credit card or via net banking (if ur in United States). We will renew your subscription automatically at the end of every billing' },

    ];

    const handleClick = (id) => { setOpenId(prevId => (prevId === id ? null : id)); };
    const handleNavigateChat = () => { navigate('/chat'); };

    return (
        <Container>

            <Box sx={{ display: 'flex', width: isSmallScreen ? '350px' : '650px', justifyContent: 'space-between', alignItems: 'center', padding: isSmallScreen ? '15px' : '0px', mt: 2 }}>
                <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={() => navigate(-1)} > <ArrowLeftIcon style={{ width: '15px' }} /> Back</Typography>

                <Typography variant="h5" color="initial" sx={{ fontWeight: '500', textAlign: 'center' }}>May I Help you</Typography>
            </Box>

            {faqs.map((faq) => (
                <Box key={faq.id} mt={2} sx={{ border: "1px solid #EAECF0", padding: "6px 11px 6px 11px", borderRadius: "8px" }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{faq.question}</Typography>
                        <IconButton onClick={() => handleClick(faq.id)}>
                            {openId === faq.id ?
                                <RemoveIcon fontSize='small' sx={{ color: "primary.main", borderRadius: "50%" }} /> :
                                <AddIcon fontSize='small' sx={{ color: "primary.main", borderRadius: "50%" }} />}
                        </IconButton>
                    </Box>
                    <Collapse in={openId === faq.id}>
                        <Box mt={1}>
                            <Typography variant='h6' sx={{ color: "#667085" }}>{faq.answer}</Typography>
                        </Box>
                    </Collapse>
                </Box>
            ))}

            <Box sx={{ padding: '30px 0 30px 0' }}>
                <Typography variant="h4" color="initial" sx={{ textAlign: 'center', padding: '15px 0 15px 0' }}>Still have a questions?</Typography>

                <Box sx={{ textAlign: 'center', marginLeft: isSmallScreen ? '0px' : isMediumScreen ? '10px' : '23%' }}>

                    <Typography variant="body1" color="initial" sx={{ display: 'flex', justifyContent: 'center', color: '#BCBCBC', width: isSmallScreen ? '365px' : '650px', textAlign: 'center' }}>If you cannot find answer to your question in
                        our Help Center, you can always contact us. We wil answer to vou shortly.</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '15px 0 15px 0' }}>
                    <Button variant="contained" sx={{ backgroundColor: 'primary.main', borderRadius: '20px', width: '150px' }} onClick={handleNavigateChat}>
                        Chat Now
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default Faq;