import React, { useState, useRef } from 'react';
import { Box, Typography, Modal, TextField, Button, Link } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    padding: '40px',
};

const MobileNumber = () => {
    const [open, setOpen] = useState(true); // To control the modal's open state
    const [mobileNumber, setMobileNumber] = useState(''); // Pre-filled mobile number
    const [otp, setOtp] = useState(['', '', '', '']); // Array for 4 OTP fields

    // References for each OTP input field
    const otpRefs = useRef([]);

    // Handle OTP input change
    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        if (!/^[0-9]$/.test(value) && value !== "") return; // Only allow digits
        
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to the next input automatically
        if (value && index < otp.length - 1) {
            otpRefs.current[index + 1].focus();
        }
    };

    // Handle key press (backspace to move back)
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            otpRefs.current[index - 1].focus();
        }
    };

    // Validate and only allow numbers in the mobile number input
    const handleMobileChange = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) { // Only allow numbers
            setMobileNumber(value);
        }
    };

    const handleClose = () => setOpen(false);

    return (
        <Box>
            <Typography variant="h5" sx={{ marginBottom: '20px' }}>Notifications</Typography> {/* Page Heading */}

            {/* Modal for mobile number and OTP */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-title" variant="h5" component="h2">
                        Update mobile number
                    </Typography>

                    {/* Mobile Number Input */}
                    <input
                        label="Mobile Number"
                        value={mobileNumber}
                        onChange={handleMobileChange}
                        InputProps={{
                            readOnly: true,
                        }}
                        style={{
                            padding: '12px 0',
                            width: '100%',
                            fontSize: '20px',
                            margin: '30px 0 15px 0',
                            border: '1px solid #BCBCBC',
                            borderRadius: '5px',
                            outline: 'none'
                        }}
                    />

                    {/* OTP Input Fields */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0 30px 0' }}>
                        {otp.map((value, index) => (
                            <TextField
                                key={index}
                                variant="outlined"
                                inputProps={{ maxLength: 1, style: { textAlign: 'center' }, pattern: '[0-9]*' }}
                                value={value}
                                onChange={(e) => handleOtpChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                sx={{ width: '45px', height: '45px' }}
                                inputRef={(el) => (otpRefs.current[index] = el)} // Reference each input
                            />
                        ))}
                    </Box>

                    {/* Resend OTP Link */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '10px' }}>
                        <Typography variant="" color="initial">Don't receive OTP?  </Typography><Link href="#" onClick={() => alert('OTP Resent')}>Resend</Link>
                    </Box>

                    {/* Actions */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}>
                        <Button variant="contained" color="primary" onClick={handleClose} sx={{ width: '150px', borderRadius: '20px' }}>Update</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default MobileNumber;
