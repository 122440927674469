import React from 'react';
import { Box } from "@mui/material";
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Membership from '../pages/Membership';
import Total from '../pages/Maid/Total';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Checkoutpage from '../pages/Checkoutpage';
import MyBookings from '../pages/Profile/Booking/MyBookings';
import BookingDetails from '../pages/Profile/Booking/BookingDetails';
import Faq from '../pages/Faq';
import ProfileSummary from '../pages/Profile/UserProfile/ProfileSummary';
import ProfileDetails from '../pages/Profile/UserProfile/ProfileDetails';
import Notifications from '../pages/Profile/UserProfile/Notifications';
import MobileNumber from '../pages/Profile/UserProfile/MobileNumber';
import AboutSevaki from '../pages/AboutSevaki';
import ContactUs from '../pages/ContactUs';
import Chat from '../pages/Chat';
import FavouriteMaids from '../pages/Maid/FavouriteMaids';
import MiniCart from '../pages/MiniCart.js'

const PageRoute = () => {
    return (
        <>
            <Box >
                <Navbar />

                <Routes>
                    {/* Main routes */}
                    <Route path='/' element={<Home />} />
                    <Route path='/membership' element={<Membership />} />
                    <Route path='/maid' element={<Total />} />
                    <Route path='/checkout' element={<Checkoutpage />} />
                    <Route path='/mybookings' element={<MyBookings />} />
                    <Route path='/bookingdetails/:id' element={<BookingDetails />} />
                    <Route path='/faq' element={<Faq />} />
                    <Route path='/contactus' element={<ContactUs />} />
                    <Route path='/minicart' element={<MiniCart />} />

                    {/* Profile related routes */}
                    <Route path="/profile" element={<ProfileSummary />}>
                        <Route path="details" element={<ProfileDetails />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="mobile-change" element={<MobileNumber />} />
                    </Route>
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/aboutus" element={<AboutSevaki />} />
                    <Route path="/favouritemaids" element={<FavouriteMaids />} />

                </Routes>

                {/* <Footer /> */}
            </Box>
        </>
    );
}

export default PageRoute;
