import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  TextField,
  Grid,
  Card,
  Select,
  MenuItem,
  Modal,
  CardContent,
  Checkbox,
  FormControlLabel,
  Divider,
  InputLabel,
} from "@mui/material";
import {
  ArrowLeft,
  BadgePercent,
  ChevronDown,
  ChevronRight,
  CircleCheck,
  Info,
  Minus,
  IconButton,
  Pencil,
  Plus,
  ShieldCheck,
  X,
} from "lucide-react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";

import miadimg from "../Assets/Images/miad-img.png";
import Breakfastimg from "../Assets/Images/breackfast-img.png";
import dosepowder from "../Assets/Images/dosepowder.png";
import useResponsive from "../hooks/useResponsive";
import Bookingsuccessfull from "../popup/BookingPopUp/Bookingsuccessfull";
import { useNavigate } from "react-router-dom";
import AddressPopup from "../popup/AddressPopup";

const Checkoutpage = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [openAccordion, setOpenAccordion] = useState(1);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [serviceFields, setServiceFields] = useState(null);
  const [referNowOpen, setReferNowOpen] = useState(false);
  const [address, setAddress] = useState(false);
  const [dynamicValues, setDynamicValues] = useState({});

  const navigate = useNavigate()

  // cooking booking form
  const [dropdownValues, setDropdownValues] = useState({
    numPeople: "4",
    numItems: "2",
    numVisits: "1",
    numBurners: "2",
  });

  const dropdownValueshandleChange = (field) => (event) => {
    setDropdownValues({ ...dropdownValues, [field]: event.target.value });
  };


  const handleReferNowClick = () => {
    setReferNowOpen(true);
  };

  const handleReferNowClose = () => {
    setReferNowOpen(false);
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleclickBack = () => {
    navigate("/maid")
  }

  const toggleAccordion = (accordionId) => {
    setOpenAccordion(openAccordion === accordionId ? 1 : accordionId);
  };

  const handleDynamicChange = (fieldName, fieldType) => (event) => {
    let value = event.target.value;

    if (fieldType === "time") {
      const [hours, minutes] = value.split(":");
      const timeObj = dayjs().hour(parseInt(hours, 10)).minute(parseInt(minutes, 10)).second(0);
      value = timeObj.format("HH:mm");
    }

    setDynamicValues((prevValues) => ({ ...prevValues, [fieldName]: value, }));
  };

  useEffect(() => {
    const storedServiceFieldsString = localStorage.getItem("serviceFields");
    console.log(storedServiceFieldsString)
    if (storedServiceFieldsString) {
      const storedServiceFields = JSON.parse(storedServiceFieldsString);

      if (storedServiceFields.serviceId) {
        setServiceFields([storedServiceFields]);
      }
      else if (Array.isArray(storedServiceFields) && storedServiceFields.length > 0 && storedServiceFields[0].title) {
        setServiceFields(storedServiceFields);
      }
      else {
        console.log("Unknown structure for storedServiceFields");
      }
    } else {
      console.log("No service fields found in local storage");
    }
  }, []);

  return (
    <Container>
      <>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mb: 2,
                  cursor: "pointer",
                }}
                onClick={handleclickBack}
              >
                <ArrowLeft sx={{ fontSize: "small" }} size={15} />
                <Typography variant="h6">Back</Typography>
              </Box>

              <Box
                sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleAccordion(1)}
                >
                  <Typography variant="h5">Address</Typography>
                  <ChevronDown
                    style={{
                      transform:
                        openAccordion === 1 ? "rotate(0deg)" : "rotate(-90deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    maxHeight: openAccordion === 1 ? "auto" : "0px",
                    opacity: openAccordion === 1 ? 1 : 0,
                    overflow: "hidden",
                    transition: "max-height 0.2s ease, opacity 0.5s ease",
                  }}
                >
                  {address ? (
                    <Card
                      sx={{
                        mt: 4,
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #E4E4E4",
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                    >
                      <CardContent>
                        <CircleCheck fill="#7E60BF" color="white" />
                      </CardContent>
                      <CardContent>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Home
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#000000" }}>
                          {address.building}, {address.flatNumber},{" "}
                          {address.street}, {address.city}, {address.pincode}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleReferNowClick}
                        >
                          <Pencil size={15} fill="#7E60BF" color="#7E60BF" />
                          <Typography variant="h6" sx={{ color: "primary.main" }}>
                            Edit
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        mt: 2,
                        color: "primary.main",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                      onClick={handleReferNowClick}
                    >
                      Add Address
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  border: "1px solid #E4E4E4",
                  p: 2,
                  borderRadius: "10px",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleAccordion(2)}
                >
                  <Typography variant="h5">Booking Form</Typography>
                  <ChevronDown
                    style={{
                      transform:
                        openAccordion === 2 ? "rotate(0deg)" : "rotate(-90deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </Box>
                {openAccordion === 2 && (
                  <Box sx={{ mt: 5 }}>
                    <Grid container spacing={2}>
                      {serviceFields[0].deepFields.map((field, index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <FormControl fullWidth sx={{ borderRadius: "10px", mb: 2 }} size="small">
                            {field.type === "select" && (
                              <>
                                <InputLabel>{field.label}</InputLabel>
                                <Select
                                  value={dynamicValues[field.name] || ""}
                                  onChange={handleDynamicChange(field.name, field.type)}
                                  label={field.label}
                                >
                                  {field.options.map((option, optionIndex) => (
                                    <MenuItem key={optionIndex} value={typeof option === "object" ? option.value : option}>
                                      {typeof option === "object" ? option.label : option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </>
                            )}
                            {field.type === "date" && (
                              <TextField
                                label={field.label}
                                type="date"
                                fullWidth
                                value={dynamicValues[field.name] || ""}
                                onChange={handleDynamicChange(field.name, field.type)}
                                InputLabelProps={{ shrink: true }}
                                sx={{ borderRadius: "10px" }}
                                size="small"
                              />
                            )}
                            {field.type === "time" && (
                              <TextField
                                label={field.label}
                                type="time"
                                fullWidth
                                value={dynamicValues[field.name] || ""}
                                onChange={handleDynamicChange(field.name, field.type)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                                sx={{ borderRadius: "10px" }}
                                size="small"
                              />
                            )}
                            {field.type === "number" && (
                              <TextField
                                label={field.label}
                                type="number"
                                fullWidth
                                value={dynamicValues[field.name] || ""}
                                onChange={handleDynamicChange(field.name, field.type)}
                                InputLabelProps={{ shrink: true }}
                                sx={{ borderRadius: "10px" }}
                                size="small"
                              />
                            )}
                            {field.type === "radio" && (
                              <FormControl component="fieldset">
                                <Typography>{field.label}</Typography>
                                <Grid container spacing={1}>
                                  {field.options.map((option, optionIndex) => (
                                    <Grid item key={optionIndex}>
                                      <FormControlLabel
                                        control={
                                          <Radio sx={{ height: 20, width: 20 }} /> // Set the size here
                                        }
                                        label={option.label || option}
                                        value={option.value || option}
                                        onChange={handleDynamicChange(field.name, field.type)}
                                        sx={{ typography: 'body2' }} // Adjust label size
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </FormControl>
                            )}
                            {field.type === "checkbox" && (
                              <>
                                <Typography variant="h6">{field.label}</Typography>
                                <Grid container spacing={1}>
                                  {field.options.map((option) => (
                                    <Grid item key={option.name}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox sx={{ height: 20, width: 20 }} /> // Set the size here
                                        }
                                        label={option.label}
                                        sx={{ typography: 'body2' }} // Adjust label size
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </>
                            )}
                          </FormControl>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>

              <Box mt={4}>
                <Box>
                  <Typography variant="h5">Cancellation policy</Typography>
                  <Typography variant="body2" sx={{ color: "#BCBCBC" }}>
                    Free cancellations if done more than 3 hrs before theservice
                    or if a professional isn’t assigned. A fee will becharged
                    otherwise.
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      mt: 1,
                      color: "primary.main",
                      textDecorationLine: "underline",
                    }}
                  >
                    Read More
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  marginTop: "10px",
                  height: isSmallScreen
                    ? "auto"
                    : isMediumScreen
                      ? "auto"
                      : "480px",
                  overflow: "scroll",
                  "&::-webkit-scrollbar": { display: "none" },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none",
                }}
              >
                <Box sx={{ border: "1px solid #E4E4E4", p: 2, borderRadius: "10px 10px 0px 0px", }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h5">Booking Details</Typography>
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{
                        fontWeight: "bold",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Update Slot
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={miadimg}
                        width="80px"
                        height="80px"
                        alt="not found"
                      />
                      <Typography variant="h5">Rohit Reddy</Typography>
                      <ShieldCheck fill="#0DC07B" color="white" />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={Breakfastimg}
                          width="80px"
                          height="80px"
                          alt="not found"
                        />
                        <Typography variant="h5">Breakfast</Typography>
                      </Box>

                      <Box>
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          color="primary"
                        >
                          ₹399.00
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #E4E4E4",
                      p: 2,
                      borderRadius: "10px",
                    }}
                    mt={2}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={dosepowder}
                          width="60px"
                          height="60px"
                          alt="not found"
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography variant="h6">Dosa Batter</Typography>
                          <Typography
                            variant="body1"
                            color="primary"
                            fontWeight="bold"
                          >
                            ₹90.34
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            border: " 1px solid #7E60BF",
                            padding: "8px 10px",
                            justifyContent: "space-between",
                            borderRadius: "10px",
                            gap: "10px",
                            backgroundColor: "#FFEDF4",
                            color: "primary.main",
                          }}
                        >
                          <Minus size={20} cursor="pointer" />
                          <Typography variant="body2" color="primary.main">
                            1
                          </Typography>
                          <Plus size={20} cursor="pointer" />
                        </Box>
                      </Box>
                    </Box>
                    <Divider></Divider>

                    <Box mt={2}>
                      <FormControl>
                        <RadioGroup sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
                          aria-labelledby="delivery-options-radio-buttons-group-label"
                          name="delivery-options-group"
                          defaultValue="withMaid"
                        >
                          <FormControlLabel
                            value="beforeMaid"
                            control={<Radio size="small" />}
                            label="Should the product be delivered before Maid?"
                          />
                          <FormControlLabel
                            value="withMaid"
                            control={<Radio size="small" />}
                            label="Arrival with Maid"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #E4E4E4",
                    p: 2,
                    borderTop: "none",
                    borderRadius: "0px 0px 10px 10px",
                  }}
                >
                  <Typography variant="h5" sx={{ mb: 1 }}>
                    Payment Summary
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Service Price</Typography>
                    <Typography variant="body2">₹399.00</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                    >
                      <Typography variant="body1" textAlign="center" fontSize="13px">
                        Add-on
                      </Typography>
                      <Info size={13} />
                    </Box>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Fees & Taxes</Typography>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Discount</Typography>
                    <Typography variant="body2">₹0.00</Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#E4E4E4",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: "#E4E4E4",
                      }}
                    />
                    <Divider
                      sx={{
                        flexGrow: 1,
                        borderColor: "#E4E4E4",
                        borderWidth: 1,
                        borderStyle: "solid",
                      }}
                    />
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        backgroundColor: "#E4E4E4",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h5" fontSize="13px"
                      sx={{ fontWeight: "bold", color: "primary.main" }}
                    >
                      Total Price
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "primary.main" }}
                    >
                      ₹599.00
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: "1px solid #E4E4E4",
                    p: 2,
                    borderRadius: "10px",
                    mt: 2,
                  }}
                >
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    Booking Slots
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Cuisine</Typography>
                    <Typography variant="body2">South Indian</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Meal Type</Typography>
                    <Typography variant="body2">Veg & Non-Veg</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Slot Type</Typography>
                    <Typography variant="body2">Daily</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Morning Timing</Typography>
                    <Typography variant="body2">8:30 AM</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="body1" fontSize="13px">Evening Timing</Typography>
                    <Typography variant="body2">6:30 PM</Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: "1px solid #E4E4E4",
                    p: 2,
                    mt: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BadgePercent size={20} fill="#0DC07B" color="#FFFFFF" />
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Coupons & Offers
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography variant="body2" color="primary">
                        4 offers available
                      </Typography>
                      <ChevronRight size={20} />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox size="small" />
                  <Typography>
                    I accept all Terms & Conditions and Privacy policy.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ padding: "8px 24px", borderRadius: "30px" }}
                  onClick={handleOpenSuccess}
                >
                  {" "}
                  Proceed to Pay
                </Button>
              </Box>
            </Grid>

          </Grid>
        </Box>

        <AddressPopup open={referNowOpen} onClose={handleReferNowClose} />

      </>
      {openSuccess && (<Bookingsuccessfull open={openSuccess} onClose={handleCloseSuccess} />)}
    </Container>
  );
};

export default Checkoutpage;
