import React, { useState, useRef } from 'react';
import { Box, Card, Typography, IconButton } from '@mui/material';
import { BadgePercent } from 'lucide-react'; // Importing Lucide icons
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

const Advertisements = () => {
    const [playing, setPlaying] = useState(false);
    const videoRef = useRef(null); // Ref to the video element

    const handlePlayButtonClick = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        if (videoRef.current) {
            videoRef.current.play(); // Play the video
            setPlaying(true); // Update state
        }
    };

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (playing) {
                videoRef.current.pause(); // Pause the video
                setPlaying(false); // Update state
            }
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            {/* Offers Section */}
            <Box sx={{ padding: '16px 0' }}>
                {/* Video with Play Button */}
                <Box
                    sx={{
                        position: 'relative',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        cursor: 'pointer',
                    }}
                >
                    <video
                        ref={videoRef} // Reference to the video element
                        style={{
                            width: '100%', // Ensure video fits within 100% of the container
                            height: '75%',
                            objectFit: 'cover',
                            borderRadius: '12px',
                        }}
                        loop
                        muted
                        controls={false} // Disable native controls
                        onClick={handleVideoClick} // Pause the video when it's clicked
                    >
                        <source
                            src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>

                    {/* Show play button only when video is paused */}
                    {!playing && (
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: '#1B3C73',
                                fontSize: '48px',
                                zIndex: 2,
                            }}
                            onClick={handlePlayButtonClick} // Play the video when play button is clicked
                        >
                            <PlayCircleFilledWhiteIcon fontSize="inherit" style={{ height: '40px' }} />
                        </IconButton>
                    )}
                </Box>

                {/* Offers Section */}
                <Card
                    sx={{
                        mt: 1,
                        borderRadius: '7px',
                        padding: '5px',
                        boxShadow: 'none',
                        border: '1px solid lightgrey',
                    }}
                >
                    <Typography variant="h6" fontWeight="bold" mb={2} ml={1.5}>
                        Offers
                    </Typography>

                    {/* ICICI Credit Card Offer */}
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BadgePercent size={15} fill="#0DC07B" color="#FFFFFF" style={{ marginLeft: '8px', backgroundColor: "#E4E4E4", padding: "5px", borderRadius: "50%" }} />
                            <Typography fontWeight="bold" sx={{ ml: 1 }}>Use ICICI Credit Card</Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" fontSize="10px" fontWeight="bold" sx={{ ml: 4.5 }}>
                            Cashback up to 50% on first order
                        </Typography>
                    </Box>

                    <hr style={{ width: "78%", marginRight: "10px", color: "#E4E4E4 !important" }} />

                    {/* CRED Cashback Offer */}
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BadgePercent size={15} fill="#0DC07B" color="#FFFFFF" style={{ marginLeft: '8px', backgroundColor: "#E4E4E4", padding: "5px", borderRadius: "50%" }} />
                            <Typography fontWeight="bold" sx={{ ml: 1 }}>
                                CRED Cashback
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" fontSize="10px" fontWeight="bold" sx={{ ml: 4.5 }}>
                            Cashback up to ₹500
                        </Typography>
                    </Box>
                </Card>

                {/* Placeholder for Ad */}
                <Card
                    sx={{
                        mt: 2,
                        height: '175px',
                        borderRadius: '8px',
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 'none',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        AD PLACE HOLDER
                    </Typography>
                </Card>
            </Box>
        </Box>
    );
};

export default Advertisements;
