import React, { useEffect, useRef, useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useResponsive from "../../hooks/useResponsive";
import "../../style/Bookingsuccessfull.css";
import { useNavigate } from 'react-router-dom';

const Bookingsuccessfull = ({onClose,open}) => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const illustrationRef = useRef(null);

  useEffect(() => {
    if (illustrationRef.current) {
      illustrationRef.current.classList.add("no-animation");
      setTimeout(() => {
        illustrationRef.current.classList.remove("no-animation");
      }, 500);
    }
  });

  const navigate=useNavigate()



 const handleNavigateToMyBookings = () => {
        navigate('/mybookings');
    };
 
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 335,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    outline: "none",
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="booking-success-title"
        aria-describedby="booking-success-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height= "125px"
 
          >
            <Box
              className="phone"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box className="illustration" ref={illustrationRef}>
                <Box className="bubbles">
                  <Box className="bubble bubble-1">
                    <Box className="bubble-inner"></Box>
                  </Box>
                  <Box className="bubble bubble-2">
                    <Box className="bubble-inner"></Box>
                  </Box>
                  <Box className="bubble bubble-3">
                    <Box className="bubble-inner"></Box>
                  </Box>
                </Box>
                <Box className="dots">
                  <Box className="dot dot-1">
                    <Box className="dot-inner"></Box>
                  </Box>
                  <Box className="dot dot-2">
                    <Box className="dot-inner"></Box>
                  </Box>
                  <Box className="dot dot-3">
                    <Box className="dot-inner"></Box>
                  </Box>
                  <Box className="dot dot-4">
                    <Box className="dot-inner"></Box>
                  </Box>
                  <Box className="dot dot-5">
                    <Box className="dot-inner"></Box>
                  </Box>
                  <Box className="dot dot-6">
                    <Box className="dot-inner"></Box>
                  </Box>
                  <Box className="dot dot-7">
                    <Box className="dot-inner"></Box>
                  </Box>
                </Box>
                <Box className="checkmark">
                  <svg
                    className="checkmark-img"
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      stroke="none"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <g
                        transform="translate(2, 2)"
                        stroke="#FFFFFF"
                        strokeWidth="3.5"
                      >
                        <polyline points="17.45 33.61 24.23 40.38 45 19.62"></polyline>
                        <circle cx="30" cy="30" r="30"></circle>
                      </g>
                    </g>
                  </svg>
                </Box>
              </Box>
            </Box>
          </Box>
           <Typography
                        id="booking-success-title"
                        variant="h6"
                        component="h2"
                        gutterBottom
                    >
                        Booking Successful!
                    </Typography>
                    <Typography
                        id="booking-success-description"
                        sx={{
                            color: 'gray',
                            width: '300px',
                            textAlign: 'center',
                            marginLeft: isSmallScreen ? '70px' : isMediumScreen ? '10px' : '20px'
                        }}
                    >
                        Congrats on your booking, the maid will reach in approximately 12 minutes.
                    </Typography>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '20px',
                                width: '150px',
                                color: 'black'
                            }}
                        >
                            Close   
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'primary.main',
                                borderRadius: '20px',
                                width: '150px'
                            }}
                            onClick={handleNavigateToMyBookings}
                        >
                            My Booking
                        </Button>
                    </Box>
        </Box>
      </Modal>
    </>
  );
};
 
export default Bookingsuccessfull;
 
 
 