import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { Box, Container, Button, Typography } from '@mui/material';
import { ArrowLeftIcon, BellIcon, UserCircleIcon } from 'lucide-react';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const ProfileSummary = () => {
    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    return (
        <Container>
            <Box sx={{margin:'30px 0 15px 0'}}>
                <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}> <ArrowLeftIcon /> Back</Typography>
            </Box>
            <Box style={{ display: 'flex', marginTop: '10px', gap: '10px', }}>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', border: '1px solid #E4E4E4', padding: '30px 20px' }}>
                    <Button
                        variant=""
                        component={Link}
                        to="/profile"
                        style={{
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            border: '1px solid #E4E4E4',
                            color: isActive('/profile') ? '#7E60BF' : '#000', // Change color when active
                            backgroundColor: isActive('/profile') ? '#7E60BF1A' : ''
                        }}
                    >
                        <UserCircleIcon style={{ width: '20px' }} /> My Profile
                    </Button>
                    <Button
                        variant=""
                        component={Link}
                        to="/notifications"
                        style={{
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            border: '1px solid #E4E4E4',
                            color: isActive('/notifications') ? '#7E60BF' : '#000',
                            backgroundColor: isActive('/notifications') ? '#7E60BF1A' : ''
                        }}
                    >
                        <BellIcon style={{ width: '20px' }} /> Notifications
                    </Button>
                    <Button
                        variant=""
                        component={Link}
                        to="/mobile-change"
                        style={{
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'start',
                            gap: '10px',
                            boxShadow: 'none',
                            border: '1px solid #E4E4E4',
                            color: isActive('/mobile-change') ? '#7E60BF' : '#000', // Change color when active
                            backgroundColor: isActive('/mobile-change') ? '#7E60BF1A' : ''
                        }}
                    >
                        <PhoneIphoneIcon style={{ width: '20px' }} /> Mobile Change
                    </Button>
                </Box>

                {/* Main Content Area */}
                <main style={{ flexGrow: 1, padding: '0 20px' }}>
                    <Box>
                        {/* Dynamically render child routes here */}
                        <Outlet />
                    </Box>
                </main>
            </Box>
        </Container>
    );
};

export default ProfileSummary;
