import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  IconButton,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Context } from "../context/Context";
import dayjs from "dayjs";
import useResponsive from "../hooks/useResponsive";

const FilterPopUp = () => {
  const { selectService, openFilterPopUp, handleCloseFilterPopUp } = useContext(Context);
  const { isSmallScreen } = useResponsive();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dynamicValues, setDynamicValues] = useState({});
  const [fetchedFields, setFetchedFields] = useState([]);

  useEffect(() => {
    const storedServiceFieldsString = localStorage.getItem("serviceFields");
    console.log("Stored service fields string:", storedServiceFieldsString);

    if (storedServiceFieldsString) {
      const storedServiceFields = JSON.parse(storedServiceFieldsString);
      console.log("Parsed service fields:", storedServiceFields);

      if (storedServiceFields.serviceId) {
        setFetchedFields([storedServiceFields]);
      } else if (Array.isArray(storedServiceFields) && storedServiceFields.length > 0) {
        setFetchedFields(storedServiceFields);
      } else {
        console.log("Unknown structure for storedServiceFields");
      }
    } else {
      console.log("No service fields found in local storage");
    }
  }, []);

  const handleDynamicChange = (fieldName, fieldType) => (event) => {
    let value = event.target.value;

    if (fieldType === "time") {
      const [hours, minutes] = value.split(":");
      const timeObj = dayjs().hour(parseInt(hours, 10)).minute(parseInt(minutes, 10)).second(0);
      value = timeObj.format("HH:mm");
    }

    setDynamicValues((prevValues) => ({ ...prevValues, [fieldName]: value }));
  };

  const handleSubmit = async () => {
    navigate("/maid");
  };

  console.log("FilterPopUp is open:", openFilterPopUp);
  console.log("Fetched Fields:", fetchedFields);
  console.log("Select Service:", selectService);

  return (
    <Dialog open={openFilterPopUp} onClose={handleCloseFilterPopUp}>
      <DialogTitle sx={{ width: 500 }}>
        <Typography variant="h6">{selectService}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseFilterPopUp}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : Array.isArray(fetchedFields) && fetchedFields.length > 0 ? (
          fetchedFields.map((fieldGroup, index) => (
            <Box key={index}>
              {/* Render basicFields if present */}
              {fieldGroup.basicFields && fieldGroup.basicFields.length > 0 && (
                <Box>
                  {fieldGroup.basicFields.reduce((rows, field, idx) => {
                    if (idx % 2 === 0) {
                      rows.push(fieldGroup.basicFields.slice(idx, idx + 2));
                    }
                    return rows;
                  }, []).map((pair, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: "flex",
                        flexDirection: isSmallScreen ? "column" : "row",
                        justifyContent: "space-between",
                        gap: isSmallScreen ? "16px" : "16px",
                        marginTop: rowIndex === 0 ? 0 : "16px",
                      }}
                    >
                      {pair.map((field, fieldIndex) => (
                        <FormControl
                          key={fieldIndex}
                          fullWidth
                          sx={{ borderRadius: "10px" }}
                          size="small"
                        >
                          {field.type === "select" && (
                            <>
                              <InputLabel>{field.label}</InputLabel>
                              <Select
                                value={dynamicValues[field.name] || ""}
                                onChange={handleDynamicChange(field.name, field.type)}
                                label={field.label}
                              >
                                {field.options.map((option, optionIndex) => (
                                  <MenuItem
                                    key={optionIndex}
                                    value={typeof option === "object" ? option.value : option === "None" ? "" : option}
                                  >
                                    {typeof option === "object" ? option.label : option}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                          {field.type === "date" && (
                            <TextField
                              label={field.label}
                              type="date"
                              fullWidth
                              value={dynamicValues[field.name] || ""}
                              onChange={handleDynamicChange(field.name, field.type)}
                              InputLabelProps={{ shrink: true }}
                              sx={{ borderRadius: "10px" }}
                              size="small"
                            />
                          )}
                          {field.type === "time" && (
                            <TextField
                              label={field.label}
                              type="time"
                              fullWidth
                              value={dynamicValues[field.name] || ""}
                              onChange={handleDynamicChange(field.name, field.type)}
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ step: 300 }}
                              sx={{ borderRadius: "10px", width: "245px" }}
                              size="small"
                            />
                          )}
                        </FormControl>
                      ))}
                    </Box>
                  ))}
                </Box>
              )}

              {/* Optionally render deepFields if present */}
              {/* {fieldGroup.deepFields && fieldGroup.deepFields.length > 0 && (
              <Typography variant="h6">Deep Fields:</Typography>
              // Additional rendering for deepFields can be added here if needed
            )} */}
            </Box>
          ))
        ) : (
          <Typography align="center">No fields available for this service.</Typography>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "24px" }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7E60BF",
              color: "#fff",
              padding: "6px 30px",
              borderRadius: "10px",
              width: isSmallScreen ? "100%" : "150px",
            }}
            onClick={handleSubmit}
            disabled={loading || !fetchedFields}
          >
            Next
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FilterPopUp;
