import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Dialog, DialogContent, Typography, Divider } from '@mui/material';
import { MapPin, X, LocateFixed } from 'lucide-react';

const locations = [
    {
        name: 'Madhapur',
        address: 'Hyderabad, Telangana, India'
    },
    {
        name: 'Madhapur Metro Station',
        address: 'Road number 36, Aditya Empire, Venkatagiri, Madhapur, Hyderabad, Telangana, India'
    },
    {
        name: 'Madhapur Ayyappa Society',
        address: 'Mega Hills, Madhapur, Hyderabad, Telangana, India'
    }
];

const LocationPopup = ({ open, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter locations based on the input value
        const filtered = locations.filter(location =>
            location.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredLocations(filtered);
    };

    const clearInput = () => {
        setSearchTerm('');
        setFilteredLocations([]);
    };

    const handleUseCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ latitude, longitude });
                    alert(`Current Location: Latitude: ${latitude}, Longitude: ${longitude}`);
                },
                (error) => {
                    alert(`Error obtaining location: ${error.message}`);
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .MuiDialog-paper': {
                    margin: 0,
                    width: '500px',
                    padding: '10px',
                    boxSizing: 'border-box',
                    borderRadius: '10px'
                }
            }}
        >
            <DialogContent>
                <Box sx={{ width: '100%' }}>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                        <MapPin size={17} style={{ position: 'absolute', left: 10, color: '#000' }} />
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Enter your location"
                            aria-label="Search location"
                            style={{
                                width: '100%',
                                padding: '10px 40px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                fontSize: '16px',
                                outline: 'none'
                            }}
                        />
                        {searchTerm && (
                            <X
                                onClick={clearInput}
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    cursor: 'pointer',
                                    color: '#888'
                                }}
                                size={20}
                                aria-label="Clear search"
                            />
                        )}
                    </div>
                    <Typography
                        onClick={handleUseCurrentLocation}
                        sx={{ display: "flex", alignItems: "center", color: "#7E60BF", padding: "20px 10px 0px 0px", cursor: 'pointer' }}
                        variant='h6'
                    >
                        <LocateFixed size={18} style={{ color: "#7E60BF", paddingRight: '8px' }} />
                        Use current location
                    </Typography>

                    <List sx={{ marginTop: 2, borderTop: "4px solid #EBEBEB" }}>
                        {filteredLocations.length > 0 ? (
                            filteredLocations.map((location, index) => (
                                <Box key={location.name}>
                                    <ListItem sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '8px 0' }}>
                                        <MapPin
                                            style={{
                                                backgroundColor: '#EBEBEB',
                                                padding: '8px',
                                                borderRadius: '50%',
                                                width: '18px',
                                                height: '18px'
                                            }}
                                        />
                                        <ListItemText
                                            primary={location.name}
                                            secondary={location.address}
                                            sx={{ overflow: 'hidden', paddingRight: '20px' }}
                                        />
                                    </ListItem>
                                    {/* Only show Divider if it's not the last item */}
                                    {index < filteredLocations.length - 1 && <Divider sx={{ height: "2px" }} />}
                                </Box>
                            ))
                        ) : (
                            <Box sx={{ padding: '8px 0' }}>
                                {/* <Typography variant="body2" sx={{ color: '#888' }}>No locations found.</Typography> */}
                            </Box>
                        )}
                    </List>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default LocationPopup;
