import React, { useState } from 'react';
import { Box, Container, Typography, IconButton } from '@mui/material';
import { ArrowLeftIcon } from 'lucide-react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';

const FavouriteMaids = () => {
    const [likedMaidIndex, setLikedMaidIndex] = useState(null);
    const navigate = useNavigate();

    const handleClick = (index) => {
        setLikedMaidIndex(likedMaidIndex === index ? null : index);
    };

    const maids = [
        {
            name: "Yogi",
            profilePicture: "https://via.placeholder.com/80",
            age: 35,
            experience: 10,
            location: "Bangalore",
            rating: 4.5,
            skills: ["South Indian cooking", "Childcare"],
            verified: true,
        },
        
        {
            name: "Mallesh",
            profilePicture: "https://via.placeholder.com/80",
            age: 41,
            experience: 9,
            location: "Goa",
            rating: 4.8,
            skills: ["North Indian cooking", "Childcare"],
            verified: true,
        },
        {
            name: "Sita",
            profilePicture: "https://via.placeholder.com/80",
            age: 35,
            experience: 6,
            location: "Alidabad",
            rating: 4.8,
            skills: ["North Indian cooking", "Childcare"],
            verified: true,
        },
    ];
    const handleHomeClick = () => {
        navigate('/maid');
    };

    return (
        <>
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '280px', margin: '20px 0', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={handleHomeClick}>
                        <ArrowLeftIcon style={{ width: '15px' }} /> Back
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: '700' }}>Favourite Maids</Typography>
                   
                </Box>

                {maids.map((maid, index) => (
                    <Box key={index}
                        sx={{  display: 'flex', alignItems: 'center',   padding: 2, borderRadius: 2,  gap:'8px',  margin:'0 0 20px 135px ',border: '1px solid #e0e0e0', boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',maxWidth: 750,
                            //  marginLeft:'135px',
                            //  marginBottom: '20px',    
                        }}>


                        <img src={maid.profilePicture || "https://via.placeholder.com/120"} alt={maid.name} style={{borderRadius: 3, width: 110, height: 125 }} />

                        <Box sx={{ flexGrow: 1, marginLeft: '15px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', marginRight: 1 }}> {maid.name} </Typography>
                                {maid.verified && <VerifiedIcon color="success" fontSize="small" />}
                            </Box>

                            <Typography color="textSecondary">  Age: {maid.age}, {maid.experience} years of experience  </Typography>

                            <Typography color="textSecondary">Service Area: {maid.location}</Typography>

                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <StarIcon color="warning" fontSize="small" />  <Typography variant="body2" sx={{ marginLeft: 0.5 }}> {maid.rating} Rating  </Typography>
                            </Box>

                            <Typography sx={{ mt: 1 }}>  Special Skills: {maid.skills.join(', ')} </Typography>
                        </Box>

                        <IconButton onClick={() => handleClick(index)}>
                            {likedMaidIndex === index ? (
                                <FavoriteIcon sx={{ color: '#ff007f' }} />
                            ) : (
                                <FavoriteBorderIcon sx={{ color: '#ff007f' }} />
                            )}
                        </IconButton>
                    </Box>
                ))}
            </Container>
        </>
    );
};

export default FavouriteMaids;
