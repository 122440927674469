import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import SofaCleaning from '../../Assets/Images/baby.png';
import Door from '../../Assets/Images/cups.png';
import Mattress from '../../Assets/Images/cooking.png';
import Ceiling from '../../Assets/Images/cuttingveg.png';
import Exhaust from '../../Assets/Images/cooking.png';
import Sink from '../../Assets/Images/baby.png';
import EmojiAnimation from '../Animations/EmojiAnimation';
import { useNavigate } from 'react-router-dom';

const services = [
    { title: "Sofa Cleaning", imgSrc: SofaCleaning },
    { title: "Door Mats Cleaning", imgSrc: Door },
    { title: "Mattress Cleaning", imgSrc: Mattress },
    { title: "Ceiling Fan Cleaning", imgSrc: Ceiling },
    { title: "Exhaust Fan Kitchen", imgSrc: Exhaust },
    { title: "Sink Cleaning", imgSrc: Sink },
];


const OtherServices = () => {
    const navigate = useNavigate()
    const handleclickBack = () => {
        navigate("/")
    }

    return (
        <Box sx={{ paddingTop: '16px' }}>

            <IconButton edge="start" color="inherit" aria-label="back" style={{ paddingTop: "20px" }}>
                <ArrowLeft height={15} width={15} />
                <Typography variant="body1" component="span" ml={1} fontSize={14}> Back </Typography>
            </IconButton>
            <Typography variant="h4" lineHeight={1.2} fontWeight="bold" my={1}> Transform your space with cleaning hacks  </Typography>


            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'flex-start', border: '1px solid grey', borderRadius: '8px', padding: 2, width: '80%', maxWidth: '400px', marginTop: "25px" }}  >
                <Typography variant="h6" fontWeight="medium" mb={2} sx={{ alignSelf: 'flex-start', paddingLeft: '20px', fontWeight: 'bold', fontSize: '18px' }} >   Other Services </Typography>

                <Box
                    sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%', marginBottom: "20px" }} >
                    {services.map((service, index) => (
                        <Box
                            key={index}
                            sx={{ textAlign: 'center', margin: 0.5, width: '80px', marginBottom: "18px" }}  >
                            <img src={service.imgSrc} alt={service.title} style={{ width: '45px', height: "45px", borderRadius: '8px' }} />
                            <Typography variant="body2" lineHeight={1.2}>{service.title}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, width: '330px' }}>
                <Typography variant="h4" lineHeight={1} fontWeight="bold" sx={{ color: 'rgba(0, 0, 0, 0.1)', width: "80%", marginTop: "20px", }} >
                    We are transparent <span style={{ display: 'flex', gap: '7px' }}>with you. Sevaki  <EmojiAnimation /></span>
                </Typography>

            </Box>
        </Box>
    );
};

export default OtherServices;
