import React from 'react'
import { Box, Container } from "@mui/material";
import OtherServices from './OtherServices';
import Maid from './Maid';
import Advertisements from './Advertisements';

const Total = () => {
    return (
        <Container>
            <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                <Box sx={{width:"30%"}}><OtherServices /></Box>
                <Box sx={{padding:"10px",width:"50%"}}><Maid /></Box>
                <Box sx={{width:"20%"}}><Advertisements /></Box>
            </Box>
        </Container>
    )
}

export default Total