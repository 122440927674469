import React from "react";
import { Box } from "@mui/material";
import HeroSection from "./HeroSection";
import AboutUs from "./AboutUs";
import Testimonials from "./Testimonials";
import Gallery from "./Gallery";
const Home = () => {
  return (
    <Box>
      <HeroSection />
      <AboutUs />
      <Testimonials />
      <Gallery />
    </Box>
  );
};

export default Home;
