import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Context } from '../../context/Context';
import { Heart, Star } from 'lucide-react';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ViewMoreDetails from '../../popup/MaidPopUps/ViewMoreDetails';
import apiUrl from "../../config"

dayjs.extend(utc);

const Maid = () => {
    const { selectService, selectedDate, selectedMorningSlot, selectCusineType } = useContext(Context);
    const [showMaidDetails, setShowMaidDetails] = useState(false);
    const [selectedMaid, setSelectedMaid] = useState([]);
    const [filteredWorkers, setFilteredWorkers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    console.log(selectedMorningSlot, "selectedMorningSlot")
    const handleOpenMaidDetails = (worker) => {
        setSelectedMaid(worker);
        setShowMaidDetails(true);
    };

    const handleCloseMaidDetails = () => {
        setShowMaidDetails(false);
    };

    const getServiceDescription = (services) => {
        const enabledServices = [];
        services.forEach(service => {
            const workDetails = service.workDetails;
            if (workDetails.cooking?.enabled) {
                enabledServices.push('Cooking');
            }
            if (workDetails.washing?.enabled) {
                enabledServices.push('Washing');
            }
            if (workDetails.cleaning?.enabled) {
                enabledServices.push('Cleaning');
            }
        });

        return enabledServices.length > 0 ? `Maid is available at these services: ${enabledServices.join(', ')}.` : '';
    };

    const handleFilter = (workers) => {
        const selectedDateTime = dayjs(selectedDate)
            .set('hour', selectedMorningSlot?.hour() || 0)
            .set('minute', selectedMorningSlot?.minute() || 0)
            .utc();
        console.log(selectedDateTime, "selectedDateTime")
        const offersServiceKey = selectService.toLowerCase();

        const availableWorkers = workers.filter(worker => {
            const offersService = worker.services && worker.services.some(service =>
                service.workDetails?.[offersServiceKey]?.enabled
            );

            const hasBookingConflict = worker.bookings && worker.bookings.some(booking => {
                const bookingDateTime = dayjs.utc(`${booking.date}T${booking.time}`);
                const conflict = bookingDateTime.isSame(selectedDateTime);
                return conflict;
            });

            return offersService && !hasBookingConflict;
        });

        setFilteredWorkers(availableWorkers);
    };

    const fetchWorkers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`http://localhost:5001/worker/getAllWorker`);
            console.log(response.data.workers)
            handleFilter(response.data.workers);
        } catch (err) {
            setError(err.response?.data?.message || err.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWorkers();
    }, []);


    return (
        <Box sx={{ paddingTop: '16px' }}>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Typography color="error">{error}</Typography>
            ) : (
                <>
                    {filteredWorkers.length > 0 ? (
                        filteredWorkers.map((worker) => (
                            <Box key={worker.id} sx={{ marginBottom: 2, display: "flex", alignItems: "center", border: "1px solid #E4E4E4", borderRadius: "10px", padding: "25px" }}>
                                <Box>
                                    <img
                                        src={worker.profilePicture}
                                        alt={worker.name}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '10px',
                                        }}
                                    />
                                </Box>
                                <Box sx={{ marginLeft: 2 }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Typography variant='h5'>
                                            {worker.name}
                                        </Typography>
                                        <VerifiedUserIcon sx={{ paddingLeft: "10px", color: "success.main" }} />
                                    </Box>
                                    <Typography variant='body1' sx={{ color: "#BCBCBC" }}>{getServiceDescription(worker.services)}</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Star color="#FFB700" fill="#FFB700" height={16} width={16} />
                                        <Typography variant='body1' sx={{ color: "#BCBCBC", paddingLeft: "10px" }}>4.5 Rating</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                    <Button sx={{ border: "1px solid #7E60BF", backgroundColor: "#f0ebfb", width: "90px", height: "35px" }} onClick={() => handleOpenMaidDetails(worker)}>Add</Button>
                                    <Button >
                                        <Heart />
                                    </Button>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                minHeight: "400px"
                            }}
                        >
                            No profiles are available based on your preference.
                        </Typography>
                    )}
                    {showMaidDetails && <ViewMoreDetails open={showMaidDetails} onClose={handleCloseMaidDetails} maid={selectedMaid} />}
                </>
            )}
        </Box>
    );
};

export default Maid;
