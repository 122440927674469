import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import {  X } from "lucide-react";
const AddressPopup = ({ open, onClose }) => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    building: "",
    flatNumber: "",
    street: "",
    pincode: "",
    city: "",
    landmark: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    building: "",
    flatNumber: "",
    street: "",
    pincode: "",
    city: "",
    landmark: "",
  });

    // const handleCollapseClick = () => {
    //   setOpen((prev) => !prev);
    // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors("");
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
  const validatePincode = (pincode) => /^\d{6}$/.test(pincode);

  const validateForm = () => {
    let newErrors = {};

    const requiredFields = [
      "name",
      "email",
      "pincode",
      "building",
      "flatNumber",
      "street",
      "city",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field]?.trim();
      if (!value) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`;
      } else {
        if (field === "email" && !validateEmail(value)) {
          newErrors.email = "Invalid email format.";
        } else if (field === "name" && !validateName(value)) {
          newErrors.name = "Name can only contain letters.";
        } else if (field === "pincode" && !validatePincode(value)) {
          newErrors.pincode = "Invalid pincode. Must be 6 digits.";
        }
      }
    });
    setErrors(newErrors);

    return Object.keys(newErrors).every((key) => !newErrors[key]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("API call with:", formData);

      setFormData({
        name: "",
        email: "",
        building: "",
        flatNumber: "",
        street: "",
        pincode: "",
        city: "",
        landmark: "",
      });
    }
  };



  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "380px" : isMediumScreen ? "400px" : "600px",
    height: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "none",
    outline: "none",
    borderRadius: "6px",
  };
  return (
    <>
      <Box>
        <Modal open={open} onClose={onClose}>
          <Box sx={modalStyle}>
     
              <Box
                sx={{
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",

                      lineHeight: isSmallScreen ? "40px" : "23.12px",
                    }}
                  >
                    Add Address
                  </Typography>
                  <X cursor="pointer" onClick={onClose} />
                </Box>
                <Box
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "#fff",
                  }}
                  component="form"
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Full name"
                        variant="outlined"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        helperText={errors.name}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Building name"
                        variant="outlined"
                        name="building"
                        value={formData.building}
                        onChange={handleChange}
                        error={!!errors.building}
                        helperText={errors.building}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Flat number"
                        variant="outlined"
                        name="flatNumber"
                        value={formData.flatNumber}
                        onChange={handleChange}
                        error={!!errors.flatNumber}
                        helperText={errors.flatNumber}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Street/Lane"
                        variant="outlined"
                        name="street"
                        value={formData.street}
                        onChange={handleChange}
                        error={!!errors.street}
                        helperText={errors.street}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Pincode"
                        variant="outlined"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                        error={!!errors.pincode}
                        helperText={errors.pincode}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        label="Select City"
                        name="city"
                        variant="outlined"
                        value={formData.city}
                        onChange={handleChange}
                        error={!!errors.city}
                        helperText={errors.city}
                        sx={{
                          // mb: 2,
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      >
                        <MenuItem value="hyderabad">Hyderabad</MenuItem>
                        <MenuItem value="Madhapur">Madhapur</MenuItem>
                        <MenuItem value="Madhapur">Madhapur</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Landmark (Optional)"
                        variant="outlined"
                        name="landmark"
                        value={formData.landmark}
                        onChange={handleChange}
                        error={!!errors.landmark}
                        helperText={errors.landmark}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              border: "1px solid #E4E4E4",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  
                  <Box display="flex" justifyContent="center" mt={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      
                      sx={{
                        width:"150px",
                        backgroundColor: "#ff007f",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#e60069",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Box>
       
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default AddressPopup;
