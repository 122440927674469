import React, { useState } from 'react';
import {
    AppBar, Toolbar, Typography, Box, IconButton, MenuItem, Select, InputBase, Drawer, List,
    ListItem, ListItemText, Container,
    TextField,
    InputAdornment
} from '@mui/material';
import { ShoppingCartIcon, CircleUserIcon, SearchIcon, MapPinIcon, ChevronDownIcon, Menu as MenuIcon, MapPin } from 'lucide-react';
import useResponsive from '../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import Loginsignup from '../popup/Loginsignup';
import ProfileMenu from '../popup/ProfileMenu';
import { styled } from '@mui/material/styles';
import TrendingSearch from '../popup/TrendingSearch';
import LocationPopup from '../popup/LocationPopup';
import MiniCart from "../pages/MiniCart"
import sevakilogo from '../Assets/Images/sevakilogo.svg'

const Navbar = () => {
    const { isSmallScreen, isMediumScreen } = useResponsive();
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [activeMenu, setActiveMenu] = useState('Home');
    const [openTrending, setOpenTrending] = useState(false)
    const [openLocation, setOpenLocation] = useState(false)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);


    const navigate = useNavigate();

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenTrending = () => {
        setOpenTrending(true)
    }

    const handleCloseTrending = () => {
        setOpenTrending(false)
    }

    const handleOpenLocation = () => {
        setOpenLocation(true)
    }

    const handleCloseLocation = () => {
        setOpenLocation(false)
    }

    const handleDrawer = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const CustomSelect = styled(Select)({
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        padding: '0px 10px',
        width: '260px',
        height: '40px',
        border: '1px solid #BCBCBC',
        marginRight: '15px',
        '& .MuiSelect-icon': {
            color: '#000000',
            fontSize: '1.2rem',
        },
        '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
        },
    });

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCart = () => {
        navigate('/minicart');
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };


    const handleNavigate = (route, menuItem) => {
        setActiveMenu(menuItem);
        navigate(route);
    };

    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem button onClick={() => handleNavigate("/", 'Home')}>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate('/store', 'Store')}>
                    <ListItemText primary="Store" />
                </ListItem>
                <ListItem button onClick={() => handleNavigate("/membership", 'Membership')}>
                    <ListItemText primary="Membership" />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
            <Box sx={{ borderBottom: '1px solid #BCBCBC' }}>
                <Container>
                    <AppBar position="sticky" color="inherit" elevation={0} sx={{ width: '100%' }}>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '4px 0 !important', width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1c2b5a', mr: 5 }}>Sevaki</Typography> */}
                                <img src={sevakilogo} style={{width:"120px"}} />
                                <Box sx={{ display: isSmallScreen || isMediumScreen ? "none" : 'flex', alignItems: 'center', ml: 4 }}>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            color: activeMenu === 'Home' ? '#7E60BF' : '#c0c0c0',
                                            fontWeight: 'bold',
                                            mx: 2,
                                            cursor: 'pointer',
                                            position: 'relative',
                                            '&::after': activeMenu === 'Home' && {
                                                content: '""',
                                                position: 'absolute',
                                                width: '100%',
                                                height: '4px',
                                                backgroundColor: '#7E60BF',
                                                bottom: '-20px',
                                                left: '0%',
                                                borderTopRightRadius: '10px',
                                                borderTopLeftRadius: '10px',
                                            },
                                        }}
                                        onClick={() => handleNavigate("/", 'Home')}
                                    >
                                        Home
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            color: activeMenu === 'Store' ? '#7E60BF' : '#c0c0c0',
                                            fontWeight: activeMenu === 'Store' ? 'bold' : 'normal',
                                            mx: 2,
                                            cursor: 'pointer',
                                            position: 'relative',
                                            '&::after': activeMenu === 'Store' && {
                                                content: '""',
                                                position: 'absolute',
                                                width: '100%',
                                                height: '4px',
                                                backgroundColor: '#7E60BF',
                                                bottom: '-20px',
                                                left: '0%',
                                                borderTopRightRadius: '10px',
                                                borderTopLeftRadius: '10px',
                                            },
                                        }}
                                        onClick={() => handleNavigate("/store", 'Store')}
                                    >
                                        Store
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            color: activeMenu === 'Membership' ? '#7E60BF' : '#c0c0c0',
                                            fontWeight: activeMenu === 'Membership' ? 'bold' : 'normal',
                                            mx: 2,
                                            cursor: 'pointer',
                                            position: 'relative',
                                            '&::after': activeMenu === 'Membership' && {
                                                content: '""',
                                                position: 'absolute',
                                                width: '100%',
                                                height: '4px',
                                                backgroundColor: '#7E60BF',
                                                bottom: '-20px',
                                                left: '0%',
                                                borderTopRightRadius: '10px',
                                                borderTopLeftRadius: '10px',
                                            },
                                        }}
                                        onClick={() => handleNavigate("/membership", 'Membership')}
                                    >
                                        Membership
                                    </Typography>
                                </Box>
                            </Box>

                            {!(isSmallScreen || isMediumScreen) && (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField
                                        placeholder="Location"
                                        size='small'
                                        variant="outlined"
                                        sx={{
                                            cursor: "pointer",
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: '#c0c0c0',
                                                    border: '1px solid #c0c0c0',
                                                    borderRadius: '5px' // Default border color
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#c0c0c0',
                                                    border: '1px solid #c0c0c0',
                                                    borderRadius: '5px' // No color change on hover
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#c0c0c0',
                                                    border: '1px solid #c0c0c0',
                                                    borderRadius: '5px' // No color change on focus
                                                },
                                            },
                                            marginRight: '10px'
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <MapPin size={18} color='black' style={{ paddingRight: '3px' }} />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <ChevronDownIcon size={18} color='black' />
                                                </InputAdornment>
                                            ),
                                            inputComponent: InputBase,
                                        }}
                                        onClick={handleOpenLocation}
                                    />


                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton color="inherit" onClick={handleOpenTrending}>
                                            <SearchIcon size={15} />
                                        </IconButton>
                                        <IconButton color="inherit">
                                            <ShoppingCartIcon size={15} onClick={handleDrawer} />
                                        </IconButton>
                                        <MiniCart isDrawerOpen={isDrawerOpen} onClose={handleDrawerClose} />
                                        <IconButton color="inherit" onClick={handleMenuOpen}>
                                            <CircleUserIcon size={15} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )}

                            {(isSmallScreen || isMediumScreen) && (
                                <IconButton onClick={toggleDrawer(true)} color="inherit">
                                    <MenuIcon size={24} />
                                </IconButton>
                            )}
                        </Toolbar>

                        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawerList()}
                        </Drawer>

                        <ProfileMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleProfileMenuClose} />
                        <Loginsignup open={openModal} setOpen={setOpenModal} />
                        {openTrending && <TrendingSearch open={openTrending} onClose={handleCloseTrending} />}
                        {openLocation && <LocationPopup open={openLocation} onClose={handleCloseLocation} />}

                    </AppBar>
                </Container>
            </Box>
        </>
    );
};

export default Navbar;