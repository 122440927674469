import React, { createContext, useEffect, useState } from "react";
import apiUrl from "../config";
import axios from "axios";

const Context = createContext();

const UserContext = ({ children }) => {
  const [selectService, setSelectService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMorningSlot, setSelectedMorningSlot] = useState(null);
  const [selectedEveningSlot, setSelectedEveningSlot] = useState(null);
  const [selectedCuisineType, setSelectedCuisineType] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [error, setError] = useState(null);
  const [serviceFields, setServiceFields] = useState(null);
  const [category, setCategory] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingServiceFields, setLoadingServiceFields] = useState(false);
  const [openServicePopUp, setOpenServicePopUp] = useState(false);
  const [openCategoryPopUp, setOpenCategoryPopUp] = useState(false);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(false);

  const handleOpenServicePopup = () => setOpenServicePopUp(true);
  const handleCloseServicePopup = () => setOpenServicePopUp(false);
  const handleOpenCategoryPopUp = () => {
    setOpenCategoryPopUp(true);
  };
  const handleCloseCategoryPopUp = () => setOpenCategoryPopUp(false);
  const handleCloseFilterPopUp = () => setOpenFilterPopUp(false);

  const handleOpenFilterPopUp = () => {
    handleCloseServicePopup();
    setOpenFilterPopUp(true);
  };

  const getServices = async () => {
    setLoadingServices(true);
    try {
      const res = await axios.get(`${apiUrl}servicetypes/getservicetypes`);
      setServices(res.data);
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch services.");
      console.error("Error fetching services:", err);
    } finally {
      setLoadingServices(false);
    }
  };

  const getServicesFields = async (val) => {
    setLoadingServiceFields(true);
    try {
      localStorage.removeItem("serviceFields");
      const response = await axios.get(`${apiUrl}servicefields/getservicefields`);
      const servicesData = response.data.services;

      if (!servicesData) {
        setServiceFields(null);
        setCategory(false);
        return;
      }

      const currentService = servicesData.find(
        (service) => parseInt(service.serviceId) === parseInt(val)
      );

      if (currentService) {
        if (!currentService.serviceCategory || currentService.serviceCategory.length === 0) {
          localStorage.setItem("serviceFields", JSON.stringify(currentService));
        }

        setServiceFields(currentService);
        setCategory(currentService.serviceCategory && currentService.serviceCategory.length > 0);
      } else {
        setServiceFields(null);
        setCategory(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch service fields.");
      console.error("Error fetching service fields:", err);
    } finally {
      setLoadingServiceFields(false);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  console.log(services, "context serviceFields");
  const contextValue = {
    selectService,
    setSelectService,
    selectedDate,
    setSelectedDate,
    selectedCuisineType,
    setSelectedCuisineType,
    selectedMorningSlot,
    setSelectedMorningSlot,
    selectedEveningSlot,
    setSelectedEveningSlot,
    category,
    services,
    error,
    serviceId,
    setServiceId,
    serviceFields,
    getServicesFields,
    loadingServices,
    loadingServiceFields,
    openCategoryPopUp,
    openServicePopUp,
    openFilterPopUp,
    handleOpenCategoryPopUp,
    handleOpenServicePopup,
    handleCloseCategoryPopUp,
    handleCloseServicePopup,
    handleOpenFilterPopUp,
    handleCloseFilterPopUp,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { Context, UserContext };
