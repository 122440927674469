import React from 'react'
import { Box, Typography, Button, Divider } from '@mui/material';
import Container from '@mui/material/Container';
import '../../App.css'

import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { User } from 'lucide-react';
import { Smile } from 'lucide-react';
import { Star } from 'lucide-react';
import { HandHeart } from 'lucide-react';
import { Store } from 'lucide-react';
import AboutUsMainImage from '../../Assets/Images/AboutUsMainImg.png'
import AboutCircle from '../../Assets/Images/AboutCircle.png'
import swiperImg1 from '../../Assets/Images/swiperImg1.png'
import swiperbg from '../../Assets/Images/swiperbg.png'

import { useNavigate } from 'react-router-dom';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import useResponsive from '../../hooks/useResponsive.js';


const slides = [
    {
        backgroundImage: swiperbg,
        title: 'Gourmet Dining, Right at Home',
        description: 'book master chief monthly/ daily beses',
        image: swiperImg1
    },
    {
        backgroundImage: swiperbg,
        title: 'Exquisite Meals, Anytime',
        description: 'hire a chef for special occasions',
        image: swiperImg1
    },
    {
        backgroundImage: swiperbg,
        title: 'Delicious Food, Delivered',
        description: 'order gourmet meals to your door',
        image: swiperImg1
    },
    {
        backgroundImage: swiperbg,
        title: 'Culinary Excellence',
        description: 'experience top-notch dining at home',
        image: swiperImg1
    },
    {
        backgroundImage: swiperbg,
        title: 'Taste the Difference',
        description: 'premium ingredients, masterfully prepared',
        image: swiperImg1
    },
    {
        backgroundImage: swiperbg,
        title: 'Savor Every Bite',
        description: 'indulge in gourmet cuisine',
        image: swiperImg1
    },
];


const AboutUs = () => {
    const navigate = useNavigate();
    const { isSmallScreen, isMediumScreen } = useResponsive()
    const handleBookNowClick = () => {
        navigate('/aboutus');
    };
    return (

        <Container
        // fixed maxWidth='1200px' sx={{ padding: isSmallScreen ? '0 10px ' : isMediumScreen ? '0' : '0 80px !important' }}
        >

            {/* About Section */}
            <Box sx={{ display: isSmallScreen ? '' : isMediumScreen ? '' : 'flex', margin: isSmallScreen ? '2rem 0' : '5rem 0' }}>
                <Box sx={{ position: 'relative', width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '55%' }}>
                    <Box sx={{ width: '100%', boxSizing: 'border-box' }} >
                        <img src={AboutUsMainImage} style={{ width: '100%', height: isMediumScreen ? '100%' : '420px' }} />
                    </Box>
                    <Box sx={{ position: "absolute", bottom: "70px", left: '-30px', display: 'flex', alignItems: 'center', backgroundColor: 'primary.contrastText', padding: '15px 30px', borderRadius: '20px', boxShadow: 'rgba(149, 157, 165, 0.3) 0px 8px 24px' }}>
                        <User style={{ color: '#0DC07B', margin: '0 10px 0 0' }} width='30' height='30' />
                        <Box>
                            <Typography variant="h5" color="initial"><b>2000+</b></Typography>
                            <Typography variant="body2" color="grey">Maids Registered</Typography>
                        </Box>
                    </Box>

                    <Box sx={{ position: 'absolute', right: isSmallScreen ? "-15px" : '-30px', bottom: '-15px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'primary.contrastText', padding: '15px', borderRadius: '20px', boxShadow: 'rgba(149, 157, 165, 0.3) 0px 8px 24px' }}>
                        <img src={AboutCircle} style={{ width: '75px' }} />
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h5" color="initial"><b>1500+</b></Typography>
                            <Typography variant="body2" color="grey">Customers</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ width: isSmallScreen ? ' 100%' : isMediumScreen ? '90%' : '45%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: isSmallScreen ? '30px 0' : isMediumScreen ? '30px' : 0, boxSizing: 'border-box' }}>
                    <Box sx={{ width: isSmallScreen ? '100%' : isMediumScreen ? '100%' : '75%' }}>
                        <Typography variant="h4" mb={2} gutterBottom>
                            <b>About us</b>
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'footer.main', lineHeight: 1.3 }}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </Typography>
                        <Box sx={{}}>

                            <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}><VerifiedRoundedIcon sx={{ color: 'success.main', marginRight: '10px', fontSize: '20px' }} />Trusted house maids</Typography>
                            <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}><VerifiedRoundedIcon sx={{ color: 'success.main', marginRight: '10px', fontSize: '20px' }} />Affordable & Transparent Pricing</Typography>
                            <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}><VerifiedRoundedIcon sx={{ color: 'success.main', marginRight: '10px', fontSize: '20px' }} />Convenience and Flexibility</Typography>
                            <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}><VerifiedRoundedIcon sx={{ color: 'success.main', marginRight: '10px', fontSize: '20px' }} /> Comprehensive Range of Services</Typography>
                            <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}><VerifiedRoundedIcon sx={{ color: 'success.main', marginRight: '10px', fontSize: '20px' }} /> Supporting Local Workers</Typography>
                            <Typography variant="h6" color="initial" sx={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}><VerifiedRoundedIcon sx={{ color: 'success.main', marginRight: '10px', fontSize: '20px' }} />24/7 Support</Typography>
                        </Box>
                        <Button variant="contained" sx={{
                            backgroundColor: "primary.main", borderRadius: '20px', width: '150px', marginTop: '10px', boxShadow: 'none', '&:hover': {
                                boxShadow: 'none'
                            }
                        }} onClick={handleBookNowClick}>
                            Read More
                        </Button>
                    </Box>
                </Box>
            </Box>

            {/* Services and ratings section */}
            <Box sx={{ margin: isSmallScreen ? '2rem 0' : '5 rem 0' }}>
                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", flexWrap: "wrap", border: '1px solid lightgrey', borderRadius: '10px', padding: isSmallScreen ? "30px 0" : '30px 30px', margin: '3rem 0px' }}>
                    <Box textAlign="center" sx={{ width: isSmallScreen ? '140px' : isMediumScreen ? '180px' : '167px', margin: '15px 0' }}>
                        <User style={{ color: '#0DC07B', margin: '5px 0' }} height='30' width='30' />
                        <Typography variant="h5">2000+</Typography>
                        <Typography variant='body2'>Verified Registers</Typography>
                    </Box>

                    <hr style={{ height: '100px', display: isSmallScreen ? 'none' : isMediumScreen ? 'none' : 'block' }} />
                    <Box textAlign="center" sx={{ width: isSmallScreen ? '140px' : isMediumScreen ? '180px' : '167px', margin: '15px 0' }}>
                        <Smile style={{ color: '#FF156A', margin: '5px 0' }} height='30' width='30' />
                        <Typography variant="h5">400+</Typography>
                        <Typography variant='body2'>Happy Clients</Typography>
                    </Box>

                    <hr style={{ height: '100px', display: isSmallScreen ? 'none' : isMediumScreen ? 'none' : 'block' }} />
                    <Box textAlign="center" sx={{ width: isSmallScreen ? '140px' : isMediumScreen ? '180px' : '167px', margin: '15px 0' }}>
                        <Star style={{ color: '#FFB700', margin: '5px 0' }} height='30' width='30' />
                        <Typography variant="h5">4.5</Typography>
                        <Typography variant='body2'>Ratings</Typography>
                    </Box>

                    <hr style={{ height: '100px', display: isSmallScreen ? 'none' : isMediumScreen ? 'none' : 'block' }} />
                    <Box textAlign="center" sx={{ width: isSmallScreen ? '140px' : isMediumScreen ? '180px' : '167px', margin: '15px 0' }}>
                        <HandHeart style={{ color: '#496CED', margin: '5px 0' }} height='30' width='30' />
                        <Typography variant="h5">8+</Typography>
                        <Typography variant='body2'>Services</Typography>
                    </Box>

                    <hr style={{ height: '100px', display: isSmallScreen ? 'none' : isMediumScreen ? 'none' : 'block' }} />
                    <Box textAlign="center" sx={{ width: isSmallScreen ? '140px' : isMediumScreen ? '180px' : '167px', margin: '15px 0' }}>
                        <Store style={{ color: '#F600F2', margin: '5px 0' }} height='30' width='30' />
                        <Typography variant="h5">10+</Typography>
                        <Typography variant='body2'>Stores</Typography>
                    </Box>
                </Box>
            </Box>

            {/* About us swiper */}
            <Swiper
                cssMode={true}
                mousewheel={true}
                keyboard={true}
                pagination={{ clickable: true }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
                className="mySwiper"
                style={{ height: isSmallScreen ? 'auto' : '330px', margin: isSmallScreen ? '2rem 0' : '5rem 0' }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide
                        key={index}
                        style={{
                            backgroundImage: `url(${slide.backgroundImage})`,
                            backgroundSize: 'cover',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : '', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ marginLeft: '2rem' }}>
                                <Typography variant="h3" color="initial" sx={{ lineHeight: '1.3', fontWeight: '800' }}>
                                    {slide.title}
                                </Typography>
                                <Typography variant="body1" color="footer.main" mt={0.5}>
                                    {slide.description}
                                </Typography>
                                <Button variant="contained" sx={{
                                    backgroundColor: 'primary.main', borderRadius: '20px', width: '150px', marginTop: '30px', boxShadow: 'none', '&:hover': {
                                        boxShadow: 'none'
                                    }
                                }}>
                                    Book Now
                                </Button>
                            </Box>
                            <img src={slide.image} height={330} alt="Slide Image" />
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>

        </Container>
    )
}

export default AboutUs