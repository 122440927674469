
import React, { useContext, useState } from "react";
import { Typography, Box, Modal, Container, Button, CircularProgress } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import customerImg from "../../Assets/Images/herosection-customer-img.png";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { X } from "lucide-react";
import fullshotsmileywomanwithlittlekid from "../../Assets/Images/full-shot-smiley-woman-with-little-kid.png";

// slide images
import bothroom1 from "../../Assets/Images/bothroom1.jpg";
import kitchen1 from "../../Assets/Images/kitchen1.jpg";
import plumber1 from "../../Assets/Images/plumber1.webp";
import cookingImg2 from "../../Assets/Images/cooking-img-2.jpg";
import bothroom2 from "../../Assets/Images/bothroom2.jpg";
import fan2 from "../../Assets/Images/fan2.jpg";
import kitchen2 from "../../Assets/Images/kitchen2.jpg";
import plumber2 from "../../Assets/Images/plumber2.jpg";
import cookingImg1 from "../../Assets/Images/cooking-img1.jpg";
import bothroom3 from "../../Assets/Images/bothroom3.jpg";
import fan3 from "../../Assets/Images/fan3.jpg";
import kitchen3 from "../../Assets/Images/kitchen3.jpg";
import plumber3 from "../../Assets/Images/plumber3.jpg";
import womanholdingpilecleanclothes from "../../Assets/Images/woman-holding-pile-clean-clothes.png";
import youngchambermaid from "../../Assets/Images/young-chambermaid-holding-glass-bottle-water-placing-wooden-table.png";
import cookingRightImg from "../../Assets/Images/cookingRightImg.png";

import useResponsive from "../../hooks/useResponsive";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Context } from "../../context/Context";
import CategoryPopUp from "../../popup/CategoryPopUp";
import ServicePopup from "./ServicePopup";
import women from "../../Assets/Images/women.png"
import men from "../../Assets/Images/men.png";
const HeroSection = () => {
  const { isSmallScreen, isMediumScreen } = useResponsive();
  const { setSelectService, services, category, getServicesFields, openServicePopUp, handleOpenServicePopup } = useContext(Context);

  const slides1 = [cookingRightImg, fullshotsmileywomanwithlittlekid, plumber1, kitchen1, bothroom1];
  const slides2 = [cookingImg2, bothroom2, fan2, kitchen2, plumber2];
  const slides3 = [bothroom3, fan3, cookingImg1, kitchen3, plumber3];
  const slides4 = [womanholdingpilecleanclothes, youngchambermaid, cookingRightImg, kitchen3, plumber3];

  const CustomSwiper = ({ slides, direction, height, width, autoplayDelay, rotate, borderRadius }) => (
    <Swiper
      cssMode={true}
      mousewheel={true}
      keyboard={true}
      pagination={{ clickable: true }}
      autoplay={{ delay: autoplayDelay, disableOnInteraction: false }}
      loop={true}
      direction={direction}
      modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
      className="mySwiper"
      style={{ height, width, transform: rotate ? "rotate(180deg)" : "none" }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide
          key={index}
          style={{
            backgroundImage: `url(${slide})`,
            backgroundSize: "cover",
            transform: rotate ? "rotate(180deg)" : "none",
            borderRadius,
          }}
        ></SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <>
      <Container>
        <Box sx={{ display: isSmallScreen ? "" : isMediumScreen ? "" : "flex", margin: "3rem 0" }}>
          <Box
            sx={{
              width: isSmallScreen ? "100%" : isMediumScreen ? "100%" : "40%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              margin: "30px 0px",
            }}
          >
            <Box sx={{ display: "" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <StarIcon sx={{ color: "#FFB700", fontSize: "large" }} />
                <Typography variant="body1" sx={{ color: "#BCBCBC" }}>{" "}4.5 Rating</Typography>
                <Typography sx={{ color: "#BCBCBC" }}>|</Typography>
                <img src={customerImg} alt="not found" />
                <Typography variant="body1" sx={{ color: "#BCBCBC" }}>{" "}4M+ Customers</Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="h2" sx={{ color: "#000000", fontWeight: "bold", width: "100%" }}>
                  Reliable Help at Your Doorstep!
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: "1px solid #BCBCBC",
                borderRadius: "10px",
                padding: "20px",
                width: isSmallScreen ? "100%" : isMediumScreen ? "70%" : "79%",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: "20px", lineHeight: isSmallScreen ? "40px" : "23.12px" }}
              >
                What are you looking for?
              </Typography>


              <Box sx={{ display: "flex", justifyContent: 'space-evenly', gap: "30px" }}>

                <Box
                  sx={{
                    border: "1px solid #BCBCBC", padding: "7px 30px", borderRadius: "10px", cursor: "pointer",
                    // height: "50px",
                  }}

                >
                  <img
                    src={men}
                    alt="not found"
                    width="45px"
                    height="45px"
                  />
                </Box>
                <Box
                  sx={{
                    border: "1px solid #BCBCBC",
                    padding: "7px 30px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    // height: "50px",
                  }}
                >
                  <img
                    src={women}
                    alt="not found"
                    width="45px"
                    height="45px"
                  />
                </Box>

              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4,}} onClick={handleOpenServicePopup}>
                <Button variant="outlined" sx={{backgroundColor:'#7E60BF' ,color:'#ffff'}}>Service's</Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: isSmallScreen ? "100%" : isMediumScreen ? "100%" : "60%" }}>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                <CustomSwiper
                  slides={slides1}
                  direction="horizontal"
                  height={isSmallScreen ? "400px" : "260px"}
                  width="400px"
                  autoplayDelay={2400}
                  borderRadius="10px 0px 0px 0px"
                />
                <CustomSwiper
                  slides={slides2}
                  direction="vertical"
                  height={isSmallScreen ? "400px" : "260px"}
                  width={isSmallScreen ? "400px" : "280px"}
                  autoplayDelay={2600}
                  borderRadius="0px 10px 0px 0px"
                />
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                <CustomSwiper
                  slides={slides3}
                  direction="vertical"
                  height={isSmallScreen ? "400px" : "260px"}
                  width={isSmallScreen ? "400px" : "280px"}
                  autoplayDelay={2500}
                  rotate={true}
                  borderRadius="0px 0px 0px 10px"
                />
                <CustomSwiper
                  slides={slides4}
                  direction="horizontal"
                  height={isSmallScreen ? "400px" : "260px"}
                  width="400px"
                  autoplayDelay={2700}
                  rotate={true}
                  borderRadius="0px 0px 10px 0px"
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {getServicesFields && <ServicePopup />}
      </Container>
    </>
  );
};

export default HeroSection;